import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

const branchesOptions = [
  { label: "Call", id: 1 },
  { label: "Email", id: 2 },
  { label: "in Person", id: 3 },
  { label: "Online Meeting", id: 4 },
];

const ScheduleForm = ({ handleClose, setSchedule, singleEnquiry }) => {
  const methods = useForm({
    defaultValues: {
      date: "",
      time: "",
      mode: "",
      scheduleComment: "",
      offSet: "",
    },
  });

  const onSend = (data) => {
    setSchedule(data, singleEnquiry?._id);
    handleClose(handleClose);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSend)} style={{ marginTop: "2rem" }}>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Controller
            control={methods.control}
            name="date"
            render={({ field }) => (
              <TextField id="outlined-basic" type="date" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
            )}
          />
          <Controller
            name="time"
            control={methods.control}
            render={({ field }) => (
              <TextField id="outlined-basic" type="time" variant="outlined" {...field} sx={{ width: "100%", mb: 2 }} />
            )}
          />
          <Controller
            control={methods.control}
            name="mode"
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={branchesOptions}
                onChange={(_, data) => onChange(data?.label)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Mode" />}
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="offSet"
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  {
                    label: "10",
                    id: 1,
                  },
                  {
                    label: "20",
                    id: 2,
                  },
                  {
                    label: "30",
                    id: 3,
                  },
                ]}
                onChange={(_, data) => onChange(data?.label)}
                renderInput={(params) => <TextField {...params} {...field} inputRef={ref} label="Notification reminder" />}
                {...field}
                sx={{ width: "100%", mb: 2 }}
              />
            )}
          />
        </Box>

        <Controller
          name="scheduleComment"
          control={methods.control}
          render={({ field }) => (
            <TextField id="outlined-basic" type="text" variant="outlined" label="Comment" {...field} sx={{ width: "100%", mb: 2 }} />
          )}
        />

        <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ScheduleForm;
