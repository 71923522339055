import React from "react";
import ComplianceForm from "./ComplianceForm";
const VisaCompliance = ({ updateVisa, visaView }) => {
  return (
    <div>
      <ComplianceForm updateVisa={updateVisa} visaView={visaView} />
    </div>
  );
};

export default VisaCompliance;
