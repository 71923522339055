import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DataModal from "./DataModal";
import AddQuery from "./AddQuery";
import { getLoggedInUser } from "../../utils/loggedInUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import LinkIcon from "@mui/icons-material/Link";
import { loadUserOptions } from "../../BussinessLogic/Leads/LeadAssignTo";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import QueryCampaignModal from "./QueryCampaignModal";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

const DataTable = ({
  createEnquiry,
  enquires,
  setSchedule,
  getSingleEnquiry,
  enquiry,
  setComment,
  getProceedData,
  setDisable,
  users,
  disable,
  getEnquiries,
  updateEnquiry,
  location,
  enquiryEditData,
  setEnquiryEditData,
  editEnquiry,
  onDelete,
  EmailComment,
  CallSchedule,
}) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [queryId, setQueryId] = useState(-1);
  const [StatusModal, setStatusModal] = useState(false);
  const [CampaignModal, setCampaignModal] = useState(false);
  const [ScoreModal, setScoreModal] = useState(false);
  const [SelectedCampaign, setSelectedCampaign] = useState("");
  const [selectedScore, setSelectedScore] = useState("");
  const [CollaboratorName, setCollaboratorName] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);

  const handleClose2 = () => {
    setOpen2(false);
    setQueryId(-1);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEnquiryEditData({});
  };

  // Modal For Query Status
  const StatusModalOpen = () => setStatusModal(true);

  const StatusModalClose = () => {
    setStatusModal(false);
    setQueryId(-1);
  };

  // For InProcess Status
  const handleProcessQuery = async () => {
    await updateEnquiry({ isProcess: true, isClosed: false }, queryId);
    getEnquiries();
    StatusModalClose();
  };
  // For Closed Status
  const handleCloseQuery = async () => {
    await updateEnquiry({ isClosed: true, isProcess: false }, queryId);
    getEnquiries();
    StatusModalClose();
  };

  // Modal For Query Campaign
  const CampaignModalOpen = () => setCampaignModal(true);

  const CampaignModalClose = () => {
    setCampaignModal(false);
    setQueryId(-1);
  };

  const handleCampaign = async (camp) => {
    setSelectedCampaign(camp);
    await updateEnquiry({ QueryCampaign: camp }, queryId);
    getEnquiries();
    CampaignModalClose();
  };

  // Modal For Query Score Board
  const ScoreModalOpen = () => setScoreModal(true);

  const ScoreModalClose = () => {
    setScoreModal(false);
    setQueryId(-1);
  };

  const handleScoreChange = async (newScore) => {
    setSelectedScore(newScore);
    await updateEnquiry({ QueryScore: newScore }, queryId);
    getEnquiries();
    ScoreModalClose();
  };

  // console.log("enquiryEditData", enquiryEditData);
  // const [tableHeight, setTableHeight] = useState();

  // useEffect(() => {
  //   const handleResize = () => {
  //     const windowHeight = window.innerHeight;
  //     const newTableHeight = windowHeight - 150; // 150px for the header
  //     setTableHeight(newTableHeight);
  //   };

  //   handleResize(); // Set initial height on component mount

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // const tableColumns = [
  //   { id: "name", label: "Name" },
  //   { id: "email", label: "Email" },
  //   { id: "actions", label: "Actions" },
  // ];

  const tableColumns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "querystatus",
      headerName: "Query Status",
      width: 190,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit Status" placement="top">
              <IconButton disabled={cellValues?.row?.data.queryMoveToRegistration}>
                <FilterTiltShiftIcon
                  onClick={() => {
                    StatusModalOpen();
                    setQueryId(cellValues?.row?.data?._id);
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      cellValues?.row?.data.isProcess === true
                        ? "Orange"
                        : cellValues?.row?.data.isClosed === true
                        ? "Red"
                        : cellValues?.row?.data.queryMoveToRegistration === true
                        ? "Green"
                        : "Grey",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>{cellValues.value}</Typography>
          </Box>
        );
      },
    },
    { field: "owner", headerName: "Owner", width: 150 },
    { field: "branch", headerName: "Branch", width: 150 },
    { field: "namePrefix", headerName: "Name Prefix", width: 150 },
    { field: "fname", headerName: "First Name", width: 150 },
    { field: "mname", headerName: "Middle Name", width: 150 },
    { field: "lname", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Emails", width: 150 },
    { field: "mobile", headerName: "Mobile No", width: 150 },
    { field: "dc", headerName: "Dialing Code", width: 150 },
    { field: "hno", headerName: "House No", width: 150 },
    { field: "dob", headerName: "DOB", width: 120 },
    {
      field: "querycampaign",
      headerName: "Query Campaign",
      width: 160,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit Campaign" placement="top">
              <IconButton disabled={cellValues?.row?.data.queryMoveToRegistration}>
                <PersonSearchIcon
                  color="primary"
                  onClick={() => {
                    CampaignModalOpen();
                    setQueryId(cellValues?.row?.data?._id);
                    getEnquiryData(cellValues?.row?.data?._id);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>{cellValues.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "queryScore",
      headerName: "Query Score",
      width: 160,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit Score" placement="top">
              <IconButton disabled={cellValues?.row?.data.queryMoveToRegistration}>
                {cellValues?.value === "Cold Lead" && (
                  <AcUnitIcon
                    sx={{
                      color: "rgb(35,115,253)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      ScoreModalOpen();
                      setQueryId(cellValues?.row?.data?._id);
                    }}
                  />
                )}
                {cellValues?.value === "Hot Lead" && (
                  <LocalFireDepartmentIcon
                    sx={{
                      color: "rgb(234,65,3)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      ScoreModalOpen();
                      setQueryId(cellValues?.row?.data?._id);
                    }}
                  />
                )}
                {cellValues?.value !== "Cold Lead" && cellValues?.value !== "Hot Lead" && (
                  <AcUnitIcon
                    sx={{
                      color: "Grey",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      ScoreModalOpen();
                      setQueryId(cellValues?.row?.data?._id);
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>{cellValues.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 230,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="View" placement="top">
              <RemoveRedEyeIcon
                color="primary"
                sx={{ mr: 1, cursor: "pointer" }}
                onClick={() => {
                  handleClick(cellValues?.row?.data?._id);
                }}
              />
            </Tooltip>
            {cellValues?.row?.data.queryMoveToRegistration === false ? (
              <Tooltip title="Edit Query" placement="top">
                <Button
                  onClick={() => {
                    setEnquiryEditData(cellValues?.row?.data);
                    setOpenModal(true);
                  }}
                  sx={{
                    p: 0,
                    minWidth: "1px !important",
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            ) : null}

            <Tooltip title="Delete Query" placement="top">
              <Button
                onClick={() => {
                  // console.log(cellValues?.row?.data);
                  onDelete({ _id: cellValues?.row?.data?._id });
                }}
                sx={{
                  p: 0,
                  minWidth: "1px !important",
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>

            {cellValues?.row?.data.queryMoveToRegistration === false ? (
              <Tooltip title="Proceed to registration" placement="top">
                <NavigateNextIcon
                  fontSize="large"
                  color="primary"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    getProceedData(cellValues?.row?.data);
                  }}
                />
              </Tooltip>
            ) : null}
            {cellValues?.row?.data.queryMoveToRegistration === false ? (
              <Box>
                <Tooltip
                  title="Assign to"
                  onClick={() => {
                    handleOpen2();
                    setQueryId(cellValues?.row?.data?._id);
                  }}
                  placement="top"
                >
                  <LinkIcon
                    fontSize="large"
                    color="primary"
                    sx={{
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  />
                </Tooltip>
              </Box>
            ) : null}
          </Box>
        );
      },
    },
  ];

  const handleClick = (data) => {
    getSingleEnquiry(data);
    handleOpen();
  };

  const getEnquiryData = (data) => {
    getSingleEnquiry(data);
  };

  const tableData = enquires?.map((enquiry, index) => {
    const counselorsNames = enquiry?.assignTo ? enquiry.assignTo.map((person) => person?.name || "N/A").join(", ") : "N/A";
    return {
      id: index + 1,
      // name: enquiry?.enquiryData?.firstName ? enquiry?.enquiryData?.namePrefix?.label + " " + enquiry?.enquiryData?.firstName : "N/A",
      querystatus:
        enquiry?.isProcess === true
          ? "In Process"
          : enquiry?.isClosed === true
          ? "Query Closed"
          : enquiry?.queryMoveToRegistration === true
          ? "Moved to Registration"
          : "Not Started" || "N.A",
      owner: counselorsNames || "N/A",
      branch: enquiry?.enquiryData?.branch?.value || enquiry?.enquiryData?.branch || "N.A",
      namePrefix: enquiry?.enquiryData?.namePrefix
        ? enquiry.enquiryData.namePrefix.label
          ? enquiry.enquiryData.namePrefix.label
          : enquiry.enquiryData.namePrefix
        : "N/A",
      fname: enquiry?.enquiryData?.firstName || "N/A",
      mname: enquiry?.enquiryData?.middleName || "N/A",
      lname: enquiry?.enquiryData?.lastName || "N/A",
      email: enquiry?.enquiryData?.infoEmail || "N/A",
      // source: enquiry?.enquiryData ? "Manual" : "Google Form",
      mobile: enquiry?.enquiryData?.cellNo || "N/A",
      dc: enquiry?.enquiryData?.dialingCode
        ? enquiry?.enquiryData?.dialingCode?.label
          ? enquiry?.enquiryData?.dialingCode.label
          : enquiry?.enquiryData?.dialingCode
        : "N/A",
      hno: enquiry?.enquiryData?.homeNo || "N/A",
      dob: enquiry?.enquiryData?.dob || "N/A",
      querycampaign: enquiry?.QueryCampaign ? enquiry?.QueryCampaign : enquiry?.enquiryData?.QueryCampaign || "N/A",
      queryScore: enquiry?.QueryScore ? enquiry?.QueryScore : enquiry?.enquiryData?.QueryScore || "N/A",
      // points: enquiry?.enquiryData?.points || "N/A",
      actions: "actions",
      data: enquiry,
    };
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };

  // const handleOptionClick = async (user) => {
  //   await updateEnquiry({ assignTo: { id: user._id, name: user.name, email: user.email } }, queryId);
  //   getEnquiries();
  //   handleClose2();
  // };

  const handleOptionClick = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.some((u) => u.id === user._id)) {
        return prevSelectedUsers.filter((u) => u.id !== user._id);
      } else {
        return [...prevSelectedUsers, { id: user._id, name: user.name, email: user.email }];
      }
    });
  };

  const handleConfirm = async () => {
    await updateEnquiry({ assignTo: selectedUsers }, queryId);
    getEnquiries();
    handleClose2();
  };

  // For Handleing Filter Method in Query Data Grid
  const [filterModel, setFilterModel] = useState({ items: [] });

  const handleCustomFilter = () => {
    const newFilterModel = {
      items: [
        {
          columnField: "mobile",
          operatorValue: "equals",
          value: location.search.substring(1),
        },
      ],
    };
    setFilterModel(newFilterModel);
  };

  useEffect(() => {
    handleCustomFilter();
  }, [location]);

  return (
    <Box
    // sx={{
    //   width: '100vw'
    // }}
    >
      <Modal open={open2} onClose={handleClose2} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Collaborator
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-checkbox-label">Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={selectedUsers}
              renderValue={(selected) => selected.map((user) => user.name).join(", ")}
              input={<OutlinedInput label="Assigne Name" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
            >
              {users
                .filter((user) => user?.role === "Counselor" || user?.email === "sfh@successfactor.org")
                .map((user) => (
                  <MenuItem key={user._id} value={user} onClick={() => handleOptionClick(user)}>
                    <Checkbox checked={selectedUsers.some((u) => u.id === user._id)} />
                    <ListItemText primary={user.name} />
                  </MenuItem>
                ))}
            </Select>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button variant="contained" sx={{ width: "90%" }} onClick={handleConfirm}>
                Confirm
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>

      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            background: "white",
            width: "70rem",
            height: "70%",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <DataModal
            handleClose={handleClose}
            singleEnquiry={enquiry}
            setSchedule={setSchedule}
            setComment={setComment}
            EmailComment={EmailComment}
            CallSchedule={CallSchedule}
          />
        </Box>
      </Modal>

      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            background: "white",
            width: "75rem",
            height: "70%",
            position: "relative",
            borderRadius: "1rem",
            overflowY: "auto",
          }}
        >
          <AddQuery
            editEnquiry={editEnquiry}
            enquiryEditData={enquiryEditData}
            handleCloseModal={handleCloseModal}
            createEnquiry={createEnquiry}
          />
        </Box>
      </Modal>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="contained" onClick={handleOpenModal} sx={{ mr: 2, mb: 2 }}>
          Add
        </Button>
      </Box>

      {/* Query Status Modal */}
      <Modal open={StatusModal} onClose={StatusModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Query Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Query Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Query Status" />}
            >
              <MenuItem onClick={handleProcessQuery}>In Process</MenuItem>
              <MenuItem onClick={handleCloseQuery}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>

      {/* Query Campaign Modal */}
      <Modal
        open={CampaignModal}
        onClose={CampaignModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <QueryCampaignModal
            handleCampaign={handleCampaign}
            SelectedCampaign={SelectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            singleEnquiry={enquiry}
            getEnquiries={getEnquiries}
            updateEnquiry={updateEnquiry}
          />
        </Box>
      </Modal>

      {/* Query Score Modal */}
      <Modal open={ScoreModal} onClose={ScoreModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Query Score Board
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Query Score Board </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={selectedScore}
              onChange={(e) => setSelectedScore(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Query Score Board" />}
            >
              <MenuItem value={"Hot Lead"} onClick={() => handleScoreChange("Hot Lead")}>
                Hot Lead
              </MenuItem>
              <MenuItem value={"Cold Lead"} onClick={() => handleScoreChange("Cold Lead")}>
                Cold Lead
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>

      {/* <TableContainer component={Paper} style={{ marginTop: "20px", }}>
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                   color:"black",
                   textTransform:"uppercase",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData?.map((row, index) => (
              <TableRow key={index}>
                {tableColumns.map((column) => (
                  <TableCell key={column.id}>{row[column.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      <Box sx={{ mx: 2 }}>
        <DataGrid
          sx={{
            height: `700px`,
          }}
          rows={tableData}
          columns={tableColumns}
          disableSelectionOnClick
          filterModel={filterModel}
          onFilterModelChange={(x) => setFilterModel(x)}
        />
      </Box>
    </Box>
  );
};

export default DataTable;
