// import { Box } from "@mui/material";
// import { useLocation } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { students } from "../../Data/StudentCardData";
// import { CRUDFunction } from "../../reduxCURD/container";
// import { getLoggedInUser } from "../../utils/loggedInUser";
// import StudentCard from "./StudentCard";
// import StudentForm from "./StudentForm";
// import { Typography } from "@mui/material";
// import TopBar from "./TopBar";
// import { curdActions } from "../../reduxCURD/actions";
// import { fileUploads } from "../../reduxRelated/actions/fileActions";
// import { DndContext } from "@dnd-kit/core";
// import NewStudentCard from "./NewStudentCard";
// import { Button } from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowLeft from "../../Images/ArrowLeft";
// import ArrowRight from "../../Images/ArrowRight";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

// const Students = (props) => {
//   // console.log(props);

//   const [studentData, setStudentData] = useState([]);
//   const [filesToUp, setFilesToUp] = useState(null);
//   const [query, setQuery] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

//   const handleCancel = () => {
//     setModal(false);
//   };
//   const itemsPerPage = 10; // Number of items to display per page
//   // console.log(studentData);
//   // console.log("the props are", props);

//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   const displayedStudents = students
//     .filter((student) => {
//       if (query === "") {
//         return student;
//       } else if (
//         student?.registrationData?.firstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.registeration?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.counselor?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.registrationData?.lastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
//         student?.registrationData?.middleName?.toLowerCase()?.includes(query?.toLowerCase())
//       ) {
//         return student;
//       }
//     })
//     .reverse()
//     .slice(startIndex, endIndex);
//   const getStudents = async () => {
//     const response = await props.getStudents();
//   };

//   function generateUniqueId() {
//     const currentDate = new Date();
//     const day = currentDate.getDate().toString().padStart(2, "0");
//     const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
//     const year = currentDate.getFullYear().toString().substr(-2);

//     return `-${day}-${month}${year}`;
//   }

//   const onSubmit = (data, files) => {
//     const user = getLoggedInUser();
//     const branch = user.branch;
//     // console.log("the user is", user);
//     const studentData = {
//       registrationData: data,
//       registeration: generateUniqueId(),
//       counselor: { id: user._id, name: user.name, email: user.email },
//       branch: branch,
//     };
//     // console.log("branch is", branch);
//     setFilesToUp(files);
//     let fileKeys = Object.keys(files);
//     fileKeys.map((k) => {
//       studentData.registrationData[k] = files[k].name;
//     });

//     props.createStudent(studentData);
//   };

//   useEffect(() => {
//     if (props.actionType === "STUDENT_CREATE_SUCCESS") {
//       setSnackbarOpen(true);
//     }
//   }, [props.actionType]);
//   //console.log(snackbarOpen);
//   useEffect(() => {
//     getStudents();
//   }, []);
//   const user = getLoggedInUser();
//   const branch = user.branch;
//   // console.log("the user is", user);
//   // console.log("the user branch is", branch);
//   const uploadFilesOnCreate = () => {
//     if (props.actionType === "STUDENT_CREATE_SUCCESS") {
//       if (filesToUp) {
//         if (!props.student?.registeration) console.log("student data not available");
//         let fileKeys = Object.keys(filesToUp);
//         fileKeys.map((k) => {
//           props.fileUploads(filesToUp[k], "/uploadsingle?stdId=" + props.student?.registeration);
//         });
//         setFilesToUp(null);
//       }
//       getStudents();
//     }
//   };

//   useEffect(() => {
//     uploadFilesOnCreate();
//   }, [props.actionType]);
//   const [modal, setModal] = useState(false);

//   return (
//     <Box
//       width="100%"
//       sx={{
//         pl: 3,
//         pr: 3,
//         py: 12,
//       }}
//     >
//       <TopBar setModal={setModal} setQuery={setQuery} />

//       {props.actionType === "STUDENTS_READ_REQUEST" ? (
//         <Typography
//           sx={{
//             pl: 3,
//             pr: 3,
//             py: 12,
//           }}
//         >
//           Loading...
//         </Typography>
//       ) : (
//         <Box width="100%" sx={{ display: "flex", flexWrap: "wrap", gap: "3.5rem 1.5rem ", marginTop: "3rem" }}>
//           {/* {students.map((item, id) => {
//           return (
//             <Link to={`/students/${item.registeration}`}>
//               <StudentCard key={item} data={item} />
//             </Link>
//           );
//         })} */}

//           {displayedStudents.map((item, id) => {
//             return (
//               <Link>
//                 <NewStudentCard data={item} key={item._id} />
//               </Link>
//             );
//           })}
//         </Box>
//       )}

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={4000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
//           Student has been created Successfully !
//         </MuiAlert>
//       </Snackbar>

//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           marginTop: "2.5rem",
//           [`@media (max-width: 459px)`]: {
//             flexDirection: "column",
//           },
//         }}
//       >
//         <Button
//           variant="contained"
//           onClick={() => setCurrentPage(currentPage - 1)}
//           disabled={currentPage === 1}
//           sx={{
//             marginRight: {
//               xs: "0",
//               md: "1rem",
//               [`@media (max-width: 459px)`]: {
//                 marginBottom: "1rem",
//               },
//             },
//           }}
//           startIcon={<ArrowLeft />}
//         >
//           Previous Page
//         </Button>
//         <Button
//           variant="contained"
//           onClick={() => setCurrentPage(currentPage + 1)}
//           disabled={endIndex >= students.length}
//           endIcon={<ArrowRight />}
//         >
//           Next Page
//         </Button>
//       </Box>

//       {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//     <Button>hello</Button>
//     <Button>hello</Button>
//   </Box> */}

//       {modal && (
//         <Box
//           sx={{
//             width: "100%",
//             height: "100vh",
//             position: "fixed",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             bgcolor: "rgba(0, 0, 0, 0.4)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             zIndex: "1212",
//           }}
//         >
//           <StudentForm setModal={setModal} setStudentData={setStudentData} onSubmit={onSubmit} studentData={studentData} />
//         </Box>
//       )}
//     </Box>
//   );
// };

// let actionOptions = {
//   create: true,
//   read: true,
//   update: true,
//   delete: true,
//   others: { fileUploads },
// };
// const studentsContainer = CRUDFunction(Students, "student", actionOptions);
// export default studentsContainer;

import { Autocomplete, Box, IconButton, MenuItem, Modal, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { students } from "../../Data/StudentCardData";
import { CRUDFunction } from "../../reduxCURD/container";
import { getLoggedInUser } from "../../utils/loggedInUser";
import StudentCard from "./StudentCard";
import StudentForm from "./StudentForm";
import { Typography } from "@mui/material";
import TopBar from "./TopBar";
import { curdActions } from "../../reduxCURD/actions";
import { fileUploads } from "../../reduxRelated/actions/fileActions";
import { DndContext } from "@dnd-kit/core";
import NewStudentCard from "./NewStudentCard";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import ArrowLeft from "../../Images/ArrowLeft";
import ArrowRight from "../../Images/ArrowRight";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AddIcon from "../../Images/AddIcon";
import SearchIcon from "../../Images/SearchIcon";
import Line from "../../Images/Line";
import { branchesOptions } from "../../Data/BranchesOptions";

const Students = (props) => {

  const students =
    props.location?.pathname == "/processing"
      ? props.students?.filter((student) => student?.isProcessing == true)
      : props.students?.filter((student) => student?.isProcessing !== true || student?.isProcessing == true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [filesToUp, setFilesToUp] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCancel = () => {
    setModal(false);
  };

  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getStudents = async () => {
    await props.getStudents();
  };

  function generateUniqueId() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().substr(-2);
    return `-${day}-${month}${year}`;
  }

  const studentTimeline = (content, status) => {
    const loggedInUser = getLoggedInUser();
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const currentTime = new Date();
    let hours = currentTime.getHours();
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${minutes}:${meridiem}`;
    const formattedDate = `${month}/${day}/${year}`;
    props.updateStudent(
      {
        timeline: {
          content: content,
          date: formattedDate,
          time: formattedTime,
          name: loggedInUser?.name,
          status: status,
        },
      },
      props.student?._id,
    );
  };

  const userRole = getLoggedInUser();

  const onSubmit = (data, files) => {
    const user = getLoggedInUser();
    const branch = user.branch;
    // console.log("the user is", user);
    const studentData = {
      registrationData: data,
      registeration: generateUniqueId(),
      counselor: { id: user._id, name: user.name, email: user.email },
      branch: branch,
    };
    // console.log("branch is", branch);
    setFilesToUp(files);
    let fileKeys = Object.keys(files);
    fileKeys.map((k) => {
      studentData.registrationData[k] = files[k].name;
    });

    props.createStudent(studentData);
  };

  // console.log("props",props.students);
  useEffect(() => {
    if (props.actionType === "STUDENT_CREATE_SUCCESS") {
      setSnackbarOpen(true);
      studentTimeline("New student created", " Student Created");
    }
  }, [props.actionType]);
  //console.log(snackbarOpen);

  useEffect(() => {
    getStudents();
  }, []);

  const user = getLoggedInUser();
  const branch = user.branch;

  // console.log("the user branch is", branch);
  const uploadFilesOnCreate = () => {
    if (props.actionType === "STUDENT_CREATE_SUCCESS") {
      if (filesToUp) {
        if (!props.student?.registeration) console.log("student data not available");
        let fileKeys = Object.keys(filesToUp);
        fileKeys.map((k) => {
          props.fileUploads(filesToUp[k], "/uploadsingle?stdId=" + props.student?.registeration);
        });
        setFilesToUp(null);
      }
      getStudents();
    }
  };

  useEffect(() => {
    uploadFilesOnCreate();
  }, [props.actionType]);

  const [modal, setModal] = useState(false);
  // const [showCards, setShowCards] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [counselorFilter, setCounselorFilter] = useState("");
  const [registerationId, setRegisterationId] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [showsearchQuery, setshowSearchQuery] = useState(false);

  const handleSearchChange = (value) => {
    if (value) {
      const fullName = `${value.registrationData?.firstName || ''} ${value.registrationData?.middleName || ''} ${value.registrationData?.lastName || ''}`.trim();
      setSearchQuery(fullName);
    } else {
      setSearchQuery("");
    }
  };

  const handleCounselorChange = (value) => {
    if (value) {
      setCounselorFilter(value);
    } else {
      setCounselorFilter("");
    }
  };

  const handleRegisterationChange = (e) => {
    setRegisterationId(e.target.value);
  };

  const handleSearch = () => {
    const newFilteredStudents = students.filter((student) => {
      const fullName = `${student?.registrationData?.firstName || ""} ${student?.registrationData?.middleName || ""} ${student?.registrationData?.lastName || ""
        }`.toLowerCase();
      const counselorName = `${student?.counselor?.name || ""}`.toLowerCase();
      const branchName = `${student?.registrationData?.branch?.text || student?.registrationData?.branch || ""}`.toLowerCase();
      const registerationID = `${student?.registeration || ""}`;

      return (
        (searchQuery === "" || fullName.includes(searchQuery.toLowerCase())) &&
        (counselorFilter === "" || counselorName.includes(counselorFilter.toLowerCase())) &&
        (branchNames.value === "" || branchName.includes(branchNames.value.toLowerCase())) &&
        (registerationId === "" || registerationID?.includes(registerationId))
      );
    });

    setFilteredStudents(newFilteredStudents);
    handleClose();
  };

  const handleSearchClick = () => {
    if (searchQuery === "" && counselorFilter === "" && branchNames === "" && registerationId === "") {
      // If all search fields are empty, fetch all students
      setFilteredStudents(students);
      setCurrentPage(1);
      setshowSearchQuery(true);
    } else {
      // Perform the regular search
      handleSearch();
    }
    handleClose();
  };

  const displayedStudents = filteredStudents.slice(startIndex, endIndex);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isMobile = useMediaQuery("(max-width:768px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
  };

  const location = useLocation();
  const isProcessingRoute = location.pathname.toLowerCase().includes("processing");

  const [branchNames, setBranchNames] = useState(branchesOptions[0]);
  const handleBranchChange = (newValue) => {
    setBranchNames(newValue);
  };

  // Dont Repeat Counselor's Name in DropDown ..
  const uniqueCounselors = [...new Set(students.map(student => student.counselor?.name).filter(name => name))];

  return (
    <Box
      width="100%"
      sx={{
        pl: 3,
        pr: 3,
        py: 12,
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            "@media (max-width: 545px)": {
              width: "70vw",
            },
            "@media (max-width: 430px)": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(25, 104, 173)",
              width: "fit-content",
              height: "55px",
              borderRadius: "1rem",
              transition: "width 0.3s ease",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ margin: "0px 0px 0px 12px" }}
              onClick={handleOpen}
            >
              <SearchIcon />
            </IconButton>
            <IconButton color="inherit" sx={{ margin: "0 0px" }}>
              <Line />
            </IconButton>
            {!isProcessingRoute && (
              <div>
                <IconButton color="inherit" sx={{ margin: "0px 15px 0px 15px" }} onClick={() => setModal(true)}>
                  <AddIcon />
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "300px",
                      marginLeft: "10px",
                      mb: "2px",
                    }}
                  >
                    Add Student
                  </Typography>
                </IconButton>
              </div>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Autocomplete
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
              value={students.find(student =>
                `${student.registrationData?.firstName} ${student.registrationData?.middleName} ${student.registrationData?.lastName}`.trim() === searchQuery
              ) || null}
              onChange={(event, value) => handleSearchChange(value)}
              options={students}
              getOptionLabel={(option) =>
                `${option.registrationData?.firstName} ${option.registrationData?.middleName} ${option.registrationData?.lastName}`
              }
              renderInput={(params) => <TextField {...params} label="Search by Name" />}
            />
            <TextField
              id="outlined-basic"
              label="Registration Id"
              variant="outlined"
              value={registerationId}
              onChange={handleRegisterationChange}
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
            />
            <Autocomplete
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
              value={uniqueCounselors.find(counselor => counselor === counselorFilter) || null}
              onChange={(event, value) => handleCounselorChange(value)}
              options={uniqueCounselors}
              renderInput={(params) => <TextField {...params} label="Counsellor" />}
            />
            <Autocomplete
              sx={{
                width: "300px",
                "@media (max-width: 1024px)": {
                  width: "250px",
                },
              }}
              value={branchNames}
              onChange={handleBranchChange}
              options={branchesOptions}
              getOptionLabel={(option) => option.text}
              renderInput={(params) => <TextField {...params} label="Branch" />}
            />
            <Button
              variant="contained"
              onClick={handleSearchClick}
              sx={{
                height: "55px",
                width: "100px",
                borderRadius: "10px",
              }}
            >
              Search
            </Button>
            <TopBar setModal={setModal} setShowFields={setShowFields} showFields={showFields} />
          </Box>
        </>
      )}
      {/* <TopBar setModal={setModal} setShowFields={setShowFields} showFields={showFields} />
      {showFields && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <TextField id="outlined-basic" label="Search by Name" variant="outlined" value={searchQuery} onChange={handleSearchChange} />
          <TextField id="outlined-basic" label="Cousellor" variant="outlined" value={counselorFilter} onChange={handleCounselorChange} />
          <TextField id="outlined-basic" label="Branch" variant="outlined" value={branchNames} onChange={handleBranchChange} />
          <TextField
            id="outlined-basic"
            label="Registeration Id"
            variant="outlined"
            value={registerationId}
            onChange={handleRegisterationChange}
          />
          <Button variant="contained" onClick={handleSearchClick}>
            Search
          </Button>
        </Box>
      )} */}
      <Box width="100%" sx={{ display: "flex", flexWrap: "wrap", gap: "3.5rem 1.5rem ", marginTop: "3rem" }}>
        {/* {students.map((item, id) => {
          return (
            <Link to={`/students/${item.registeration}`}>
              <StudentCard key={item} data={item} />
            </Link>
          );
        })} */}

        {displayedStudents.map((item, id) => {
          return (
            <Link>
              <NewStudentCard
                data={item}
                key={item._id}
                location={props.location.pathname}
                updateStudent={props.updateStudent}
                getStudents={getStudents}
                isProcessingRoute={isProcessingRoute}
              />
            </Link>
          );
        })}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert variant="filled" onClose={handleCloseSnackbar} severity="success">
          Student has been created Successfully !
        </MuiAlert>
      </Snackbar>
      {!showsearchQuery && filteredStudents?.length > 0 &&
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2.5rem",
            [`@media (max-width: 459px)`]: {
              flexDirection: "column",
            },
          }}
        >
          <Button
            variant="contained"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{
              marginRight: {
                xs: "0",
                md: "1rem",
                [`@media (max-width: 459px)`]: {
                  marginBottom: "1rem",
                },
              },
              width: "170px"
            }}
            startIcon={<ArrowLeft />}
          >
            Previous Page
          </Button>
          <Button
            variant="contained"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={endIndex >= filteredStudents.length}
            sx={{
              width: "170px"
            }}
            endIcon={<ArrowRight />}
          >
            Next Page
          </Button>
        </Box>
      }

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
    <Button>hello</Button>
    <Button>hello</Button>
  </Box> */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Autocomplete
              fullWidth
              value={students.find(student =>
                `${student.registrationData?.firstName} ${student.registrationData?.middleName} ${student.registrationData?.lastName}`.trim() === searchQuery
              ) || null}
              onChange={(event, value) => handleSearchChange(value)}
              options={students}
              getOptionLabel={(option) =>
                `${option.registrationData?.firstName} ${option.registrationData?.middleName} ${option.registrationData?.lastName}`
              }
              renderInput={(params) => <TextField {...params} label="Search by Name" />}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Registration Id"
              variant="outlined"
              value={registerationId}
              onChange={handleRegisterationChange}
            />
            <Autocomplete
              fullWidth
              value={uniqueCounselors.find(counselor => counselor === counselorFilter) || null}
              onChange={(event, value) => handleCounselorChange(value)}
              options={uniqueCounselors}
              renderInput={(params) => <TextField {...params} label="Counsellor" />}
            />
            <Autocomplete
              fullWidth
              value={branchNames}
              onChange={handleBranchChange}
              options={branchesOptions}
              getOptionLabel={(option) => option.text}
              renderInput={(params) => <TextField {...params} label="Branch" />}
            />
            <Button
              variant="contained"
              onClick={handleSearchClick}
              fullWidth
              sx={{
                height: "45px",
                borderRadius: "10px",
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Modal>
      {modal && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <StudentForm setModal={setModal} setStudentData={setStudentData} onSubmit={onSubmit} studentData={studentData} />
        </Box>
      )}
    </Box>
  );
};

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
  others: { fileUploads },
};
const studentsContainer = CRUDFunction(Students, "student", actionOptions);
export default studentsContainer;
