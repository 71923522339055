import React from "react";
import { ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CancelIcon from '@mui/icons-material/Cancel';

function NotificationMessage({ onClick, isRead, message, comment, time, notification, props }) {
  // console.log(notification)
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };
  const formattedDate = new Date(time).toLocaleDateString(undefined, dateOptions);
  const formattedTime = new Date(time).toLocaleTimeString(undefined, timeOptions);

  const handleDeleteNotification = async (id) => {
    await props.deleteNotification(id);
    props.getNotifications();
  };

  return (
    // <ListItem button onClick={onClick}>
    //   <ListItemIcon
    //   >
    //     <NotificationsIcon style={{ color: isRead ? null : "rgb(25,104,173)" }} />
    //   </ListItemIcon>
    //   <ListItem>
    //     <ListItemText primary={<span style={isRead ? null : unreadStyle}>{comment}</span>} secondary={message} />
    //   </ListItem>
    //   {/* <ListItemText secondary={formattedDate} /> */}
    // </ListItem>
    <>
      <ListItem button onClick={onClick}
        style={{
          borderBottom: "1px solid rgb(235,235,235)",
          backgroundColor: isRead ? "none" : "rgb(233,240,243)"
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0.4rem 0.4rem"
        }}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <NotificationsIcon
              style={{ color: isRead ? "rgb(121,139,154)" : "rgb(25,104,173)" }}
            />
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "15px"
          }}>
            <Typography
              variant={isRead ? "body1" : "subtitle1"}
            >
              {comment}
            </Typography>

            <Typography
              variant={isRead ? "body2" : "10px"}
            >
              {message}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              {formattedDate}, {formattedTime}
            </Typography>
          </div>

          <Tooltip title="Delete" placement="top">
            <CancelIcon style={{ marginTop: "-2px", marginRight: "-2px", color: "rgb(236,0,13)" }} fontSize="small"
              // onClick={(e) => {
              //   handleDeleteAllNotifications({ userId: notification?.sendTo?.id });
              // }}
            onClick={(e) => {
              e.stopPropagation(); // Prevents click event from bubbling up to ListItem
              handleDeleteNotification({ _id: notification?._id });
            }}
            />
          </Tooltip>
        </div>
      </ListItem>
    </>
  );
}

export default NotificationMessage;
