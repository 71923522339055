import React, { useEffect, useState } from "react";
import GmailUi from "./GmailUi";
import { CRUDFunction } from "../../../../reduxCURD/container";
import { Box, Button, Snackbar, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { fileUploads } from "../../../../reduxRelated/actions/fileActions";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";
// ../../reduxRelated/actions/fileActions

const Gmail = (props) => {
  console.log(props);

  const [sender, setSender] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachment, setAttachment] = useState("");
  const [settingOpen, setSettingOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [attachmentNames, setAttachmentNames] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState(null);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  //   const sendSearch = () => {
  //     props.getGmails("?search=" + `${sender || ""}-${subject || ""}-${body || ""}-${attachment || ""}`);
  //   };

  const { id } = useParams();

  const getMail = (gId) => {
    props.getGmail(`${gId} ${id}`);
  };

  useEffect(() => {
    if (props?.singleApplicationData?.filterObject) {
      props.getGmails(
        "?search=" +
          `${props.singleApplicationData?.filterObject?.sender || ""}-${props.singleApplicationData?.filterObject?.subject || ""}-${
            props.singleApplicationData?.filterObject?.body || ""
          }-${props.singleApplicationData?.filterObject?.mail || ""}`,
      );
    } else {
      props.getGmails();
    }
  }, []);

  const onSubmit = (data) => {
    const filterObject = {};
    data.mailFilters.forEach((item) => {
      filterObject[item.filter.value] = item.filterValue;
    });

    props.getGmails(
      "?search=" + `${filterObject.sender || ""}-${filterObject.subject || ""}-${filterObject.body || ""}-${filterObject.mail || ""}`,
    );
    props.onUpdate(
      {
        filterObject: filterObject,
      },
      props?.singleApplicationData?._id,
    );
    setSettingOpen(false);
  };

  const onMailSend = (data, gId, messageId, subject, email) => {
    props.createGmail({
      data,
      gId,
      messageId,
      subject,
      email,
      attachments: attachmentNames,
    });

    attachmentFiles.map((file) => props.fileUploads(file, "/uploadsingle?stdId=" + id));
    setAttachmentFiles(null);
    setAttachmentNames([]);
  };

  useEffect(() => {
    if (props.actionType == "GMAIL_CREATE_SUCCESS") {
      setSnackbarOpen(true);
    }
  }, [props.actionType == "GMAIL_CREATE_SUCCESS"]);

  const uploadAttachments = (e) => {
    // console.log(e.target.files);
    const arrayOfObjects = Object.values(e.target.files);
    setAttachmentFiles(arrayOfObjects);

    const fileNames = arrayOfObjects.map((file) => {
      return {
        filename: file.name,
        path: studentFileDownloadPath(id, file.name),
      };
    });

    setAttachmentNames(fileNames);
  };

  // console.log(attachmentFiles);
  // console.log(attachmentNames);

  return (
    <div>
      {/* <Box>
        <TextField placeholder="Sender..." onChange={(e) => setSender(e.target.value)} />
        <TextField placeholder="Subject..." onChange={(e) => setSubject(e.target.value)} />
        <TextField placeholder="Body..." onChange={(e) => setBody(e.target.value)} />
        <TextField placeholder="Has attachment..." onChange={(e) => setAttachment(e.target.value)} />
        <Button onClick={sendSearch}>Search</Button>
      </Box> */}

      <GmailUi
        settingOpen={settingOpen}
        setSettingOpen={setSettingOpen}
        onSubmit={onSubmit}
        mails={props?.gmails}
        mail={props?.gmail}
        getMail={getMail}
        actionType={props?.actionType}
        onMailSend={onMailSend}
        uploadAttachments={uploadAttachments}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
          Reply send successfully
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

let actionOptions = {
  create: true,
  read: true,
  update: true,
  delete: true,
  others: { fileUploads },
};

const GmailContainer = CRUDFunction(Gmail, "gmail", actionOptions);
export default GmailContainer;
