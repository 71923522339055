import { Stack, Typography, TextField, MenuItem, Button } from "@mui/material";
import React, { useState } from "react";
import InterviewDialog from "./InterviewDialog";

const ComplianceForm = ({ updateVisa, visaView }) => {
  //   console.log("visaView", visaView);
  // State for modal for deadline interview
  const [open, setOpen] = useState(false);
  // State for interview link and pass/fail selection and set Interview Date
  const [interviewLink, setInterviewLink] = useState("");
  const [interviewResult, setInterviewResult] = useState("");
  const [date, setDate] = useState("");

  // Methods for opening and closing modals
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle change in interview link field
  const handleInterviewLinkChange = (event) => {
    setInterviewLink(event.target.value);
  };

  // Handle change in pass/fail dropdown
  const handleInterviewResultChange = (event) => {
    setInterviewResult(event.target.value);
  };

  const handleSubmit = () => {
    if (!date || !interviewLink || !interviewResult) {
      alert("Please fill all the fields.");
      return;
    }
    const updatedData = {
      visaOfficerForm: {
        date,
        interviewLink,
        interviewResult,
      },
    };
    updateVisa(updatedData, visaView?._id);
  };

  return (
    <Stack
      sx={{
        p: "25px 30px",
        mb: 6,
        gap: "20px",
        // add media query
        ["@media (max-width: 560px)"]: {
          mx: 0,
        },
      }}
    >
      <Typography sx={{ color: "primary.main" }} variant={"h3"}>
        Compliance Form
      </Typography>

      <Typography sx={{ color: "primary.main", cursor: "pointer" }} component={"div"} onClick={handleClickOpen}>
        Interview Schedule : {visaView?.visaOfficerForm?.date}
      </Typography>

      {/* Interview Link TextField */}
      <TextField label="Interview Link" variant="outlined" value={interviewLink} onChange={handleInterviewLinkChange} fullWidth />
      <Typography sx={{ color: "primary.main" }} component={"div"}>
        Interview Link : {visaView?.visaOfficerForm?.interviewLink}
      </Typography>
      {/* Interview Result Dropdown */}
      <TextField
        select
        label="Interview Result"
        variant="outlined"
        value={interviewResult}
        onChange={handleInterviewResultChange}
        fullWidth
      >
        <MenuItem value="pass">Pass</MenuItem>
        <MenuItem value="fail">Fail</MenuItem>
      </TextField>

      <Typography sx={{ color: "primary.main" }} component={"div"}>
        Interview Result : {visaView?.visaOfficerForm?.interviewResult}
      </Typography>

      {/* <Typography sx={{ color: "primary.main" }} component={"div"}>
        <a href="tel:number">03214280789</a>
      </Typography> */}
      {visaView?.visaOfficerForm?.interviewResult !== "pass" ? (
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      ) : null}

      {/* Interview Dialog Component */}
      <InterviewDialog open={open} handleClose={handleClose} date={date} setDate={setDate} />
    </Stack>
  );
};

export default ComplianceForm;
