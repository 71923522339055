import React from 'react'

const ArrowLeft = ({color}) => {
  return (
    <div>
        <svg width="30" height="13" viewBox="0 0 30 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.474791 5.7146C0.179096 6.00466 0.17453 6.47951 0.464593 6.77521L5.19145 11.5938C5.48152 11.8895 5.95637 11.8941 6.25206 11.604C6.54776 11.314 6.55233 10.8391 6.26226 10.5434L2.06061 6.2602L6.34385 2.05855C6.63954 1.76848 6.64411 1.29363 6.35405 0.997935C6.06398 0.702239 5.58913 0.697673 5.29343 0.987737L0.474791 5.7146ZM53.0072 6.00003L1.00721 5.50003L0.992787 6.99997L52.9928 7.49997L53.0072 6.00003Z" fill="white"/>
</svg>

    </div>
  )
}

export default ArrowLeft