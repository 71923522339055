import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Drawer, Grid, Paper, Typography } from "@mui/material";
import { CRUDFunction } from "../../reduxCURD/container";
import { curdActions } from "../../reduxCURD/actions";
import { students } from "../../Data/StudentCardData";
import Widgets from "./Widgets";
import { getLoggedInUser } from "../../utils/loggedInUser";
// console.log("curdActions", curdActions);

const DashBoardV2 = (props) => {
  // console.log({ props });

  //console.log(defaultStatusData);

  const [notificationsData, setNotifications] = useState([]);

  const [DashboardFilters, setDashboardFilters] = useState({
    dateRange: { from: "2024-01-01", to: "2024-08-30", today: "" },
    branch1: "",
    user1: "",
  });

  useEffect(() => {
    const jsonArrayFilters = encodeURIComponent(JSON.stringify(DashboardFilters));
    const url = `/?dateRange=${jsonArrayFilters}`;
    props?.getDashboards(url);
  }, []);

  // const [branchValue, setBranchDashboard] = useState("");
  // console.log("appSatgesData", appStagesData);

  let prevNotificationType = usePrevious(props?.dashboardNotificationActionType, notificationActionCallback);

  useEffect(() => {
    props?.getDashboardNotifications("/dashboardNotifications");
  }, []);

  const getUsers = () => {
    props?.getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  function usePrevious(value, callBack) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
      callBack(value);
    }, [value]);
    return ref.current;
  }
  //console.log("sessionData is", sessionData);
  // console.log("sessionData is",sessionData);
  function notificationActionCallback(notiActionType) {
    if (prevNotificationType !== notiActionType && props?.dashboardNotificationActionType === "DASHBOARDNOTIFICATIONS_READ_SUCCESS") {
      setNotifications(props?.dashboardNotifications);
    }
  }

  const [deleteWidget, setDeleteWidget] = useState(false);

  useEffect(() => {
    const user = getLoggedInUser();
    props?.getUser(user?.email);
  }, []);

  return (
    <Box sx={{ pl: 3, pr: 3, py: 12, display: "flex" }}>
      <Box
        sx={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Widgets
          dashboardData={props?.dashboards}
          notificationsData={notificationsData}
          deleteWidget={deleteWidget}
          updateUser={props?.updateUser}
          getUser={props?.getUser}
          userData={props?.user}
          users={props?.users}
          DashboardFilters={DashboardFilters}
          setDashboardFilters={(fil) => setDashboardFilters(fil)}
          getDashboards={props?.getDashboards}
        />

        {/* Droppable */}
      </Box>
      {/* Drawer */}
    </Box>
    // </DndContext>
  );
};

let variableList = {
  userReducer: {
    users: [],
    user: {},
  },
};
// console.log("curdActions are",curdActions);
const updateUser = curdActions?.updateUser;
const getUser = curdActions?.getUser;
const getUsers = curdActions?.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    updateUser,
    getUser,
    getUsers,
  },
};
let addditionalREducer = ["userReducer"];
let customApi = [
  {
    name: "dashboardNotification",
    apiName: "notification",
  },
];
const DashBoardV2Container = CRUDFunction(DashBoardV2, "dashboard", actionOptions, variableList, addditionalREducer, null, customApi);
export default DashBoardV2Container;
