import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ApplicationStatus from "./ApplicationStatus";
import DocumentCheckList from "./DocumentCheckList";
import DocumentCheckListDisplay from "./DocumentCheckListDisplay";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import ApplicationCard from "./ApplicationCard";
import AllApplications from "./AllApplications";
import ConfirmationModal from "../../../Components/ConfirmationModal";
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Escalated": {
    backgroundColor: theme.palette.info.main,
  },
}));

const ActiveApplications = ({
  setAppDetail,
  setShowForm,
  applicationDataFinal,
  onUpdate,
  applications,
  appDetail,
  onDelete,
  onEdit,
  edit,
  student,
  setEdit,
  setApplicationDataFinal,
  setDeleteOpen,
  selectedOption,
  data,
  setData,
  showCard,
  pageLocation,
  selectedTab,
}) => {
  // console.log({applications});
  let openApplications = applications?.filter((item) => {
    return !item?.closed?.closed;
  });

  let transformedData = openApplications
    ?.filter((app) => app.appData.status !== "no")
    ?.map((item, index) => ({
      id: item?.formId,
      uid: item?._id,
      universityName: item?.appData?.university?.label,
      intendedCourse: item?.appData?.course?.label,
      intake: item?.appData?.intake?.label,
      year: item?.appData?.year,
      status:
        item?.stage === 0
          ? "Data Collection"
          : item?.stage === 1
          ? "Submission"
          : item?.stage === 2
          ? "Decision"
          : item.stage === 3
          ? "Response"
          : item?.stage === 4
          ? "Visa"
          : "",

      escalation: item?.escalation?.value ? "True" : "", // jab hum escalate karkai karkai submit karlain gain tw iski value true hujaye gi

      edit: "Edit",
      delete: "delete",
      disable: pageLocation == "processing" ? false : true,
    }));

  const columns = [
    { field: "id", headerName: "Application Id", width: 200 },
    { field: "universityName", headerName: "University Name", width: 230 },
    { field: "intendedCourse", headerName: "Intended course", width: 230 },
    { field: "intake", headerName: "Intake", width: 200 },
    { field: "status", headerName: "Status", width: 200 },
    {
      field: "escalation",
      headerName: "Escalation",
      width: 200,
      renderCell: (params) => {
        //console.log("escallation value is", params.row.escalation);
        return <React.Fragment>{params.row.escalation && <RunningWithErrorsIcon color="info" sx={{ ml: 2 }} />}</React.Fragment>;
      },
    },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton color="primary" onClick={() => onEdit(params.row.uid)}>
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton color="error" onClick={() => onDelete({ _id: params.row.uid })}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];
  let rows = transformedData;

  useEffect(() => {
    if (data) {
      let singleApplication = applications?.find((item) => {
        return item?._id === data._id;
      });
      setData(singleApplication);
    }
  }, [applications]);

  // let selectedOption = (ids) => {
  //   let singleApplication = applications?.find((item) => {
  //     return item?.appData?.id === ids[0];
  //   });
  //   setAppDetail(true);
  //   setData(singleApplication);
  // };

  return (
    <Stack spacing={2}>
      {/* <Stack direction="row" justifyContent="space-between">
        <Typography></Typography>
        <Button variant="contained" onClick={newApplication}>
          New Application Form
        </Button>
      </Stack> */}
      {/* <Box
        sx={{
          height: 400,
        }}
      >
        <StyledDataGrid
          checkboxSelection={false}
          rows={rows}
          columns={columns}
          pageSize={5}
          // disableSelectionOnClick
          rowsPerPageOptions={[5]}
          onSelectionModelChange={(ids) => selectedOption(ids)}
        />
      </Box> */}
      <AllApplications
        applications={applications}
        selectedOption={selectedOption}
        rows={rows}
        onEdit={onEdit}
        onDelete={onDelete}
        showCard={showCard}
        pageLocation={pageLocation}
        selectedTab={selectedTab}
      />
      <Box>
        {/* <Box py={4}>{appDetail && data && <ApplicationStatus student={student} data={data} onUpdate={onUpdate} />}</Box> */}
        {/* <Box py={4}>{appDetail && data && <DocumentCheckListDisplay data={data} student={student} />}</Box> */}
        {/* <Box py={4}>{appDetail && data && <DocumentCheckList data={data} student={student} />}</Box> */}
      </Box>
      {/* {showModal && <ConfirmationModal />} */}
    </Stack>
  );
};

export default ActiveApplications;
