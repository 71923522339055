import React, { useEffect } from "react";
import { CRUDFunction } from "../../reduxCURD/container";
import VisaCard from "./VisaCard";
const Visa = (props) => {
  // console.log("props", props);

  const getVisas = async () => {
    props.getVisas();
  };

  useEffect(() => {
    getVisas();
  }, []);

  return (
    <div>
      <VisaCard visas={props.visas} />
    </div>
  );
};

const visaContainer = CRUDFunction(Visa, "visa", null, null, null, "visa");
export default visaContainer;
