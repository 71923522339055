const countryDocumentNames = {
  "United States": "l20 Document Checklist",
  Australia: "Uoe Document Checklist",
  Canada: "Cas Document Checklist",
  "United Kingdom": "Uk Document Checklist",
};

const countryDocuments = [
  {
    country: "United States",
    documents: [
      { id: 1, title: "Passport", fieldName: "passport" },
      { id: 2, title: "Academic Documents", fieldName: "academic" },
      { id: 3, title: "IELTS / TOFFEL / English Proficiency Certificate", fieldName: "epc" },
      { id: 4, title: "Personal Job Letter / CV", fieldName: "pjl" },
      { id: 5, title: "Affidavit of Support", fieldName: "aos" },
      { id: 6, title: "Personal Bank Letter & Bank Statement", fieldName: "pbl" },
      { id: 7, title: "Job Proof", fieldName: "jp" },
      { id: 8, title: "Salary Certificate ,Income Statement , BalanceSheet.", fieldName: "sc" },
      { id: 9, title: "Photocopy of Saving Certificate", fieldName: "posc" },
      { id: 10, title: "Property Documents", fieldName: "pod" },
      { id: 11, title: "Property Valuation Letter", fieldName: "pvl" },
      { id: 12, title: "Tax Return", fieldName: "tr" },
      { id: 13, title: "Family B Form", fieldName: "fbf" },
      { id: 14, title: "Birth Certificate", fieldName: "bc" },
      { id: 15, title: "FRC", fieldName: "frc" },
      { id: 16, title: "02 Photographs", fieldName: "photographs" },
      { id: 17, title: "NIC student and Parents", fieldName: "nic" },
      { id: 18, title: "SEVIS", fieldName: "sevis" },
      { id: 19, title: "Visa Application Fee", fieldName: "vaf" },
      { id: 20, title: "Requirement of Poliovirus Certification", fieldName: "ropc" },
    ],
  },
  {
    country: "Australia",
    documents: [
      { id: 1, title: "IELTS / English Proficiency Certificate", fieldName: "Aepc" },
      { id: 2, title: " Resume / CV", fieldName: "aresume" },
      { id: 3, title: "Work experience letters", fieldName: "awel" },
      { id: 4, title: " Educational Documents", fieldName: "aed" },
      { id: 5, title: "Statement of purpose", fieldName: "asop" },
      { id: 6, title: " Notarized colored copy of Passport", fieldName: "anccop" },
      { id: 7, title: "Complete passports", fieldName: "acp" },
      { id: 8, title: "ECOE", fieldName: "aecoc" },
      { id: 9, title: "Polio Certificate", fieldName: "apc" },
      { id: 10, title: "Police Character Certificate", fieldName: "apcc" },
      { id: 11, title: "FRC", fieldName: "afrc" },
      { id: 12, title: "Birth Certificate", fieldName: "abc" },
      { id: 13, title: "passport-sized photograph", fieldName: "apsp" },
      { id: 14, title: "ID Card Copy", fieldName: "aicc" },
      { id: 15, title: " Affidavit of Support", fieldName: "aaos" },
      { id: 16, title: "Bank Letter. & Bank Statement", fieldName: "ablbs" },
      { id: 17, title: "Tax Returns", fieldName: "atr" },
      { id: 18, title: "Evidence of Source of Income", fieldName: "aeosoi" },
      { id: 19, title: "Photocopy of Saving Certificate", fieldName: "apsc" },
      { id: 20, title: "Property Documents", fieldName: "apd" },
      { id: 21, title: " Property Valuation Letter", fieldName: "apvl" },
      { id: 22, title: "Employment Letter", fieldName: "aeml" },
      { id: 23, title: "Salary slips", fieldName: "asalaryslips" },
      { id: 24, title: "Salaried Bank Statement", fieldName: "asalariedbs" },
    ],
  },
  {
    country: "Canada",
    documents: [
      { id: 1, title: "All passports", fieldName: "capassports" },
      { id: 2, title: "Academic Documents Original", fieldName: "cadoriginal" },
      { id: 3, title: "Statement of Entry Photocopy", fieldName: "csofphotocopy" },
      { id: 4, title: "Predicted Grades photocopy", fieldName: "cpgphotocopy" },
      { id: 5, title: "IELTS / TOEFL", fieldName: "cilets" },
      { id: 6, title: "Statement of Purpose", fieldName: "csopurpose" },
      { id: 7, title: "Personal Job Letter / CV", fieldName: "cpjletter" },
      { id: 8, title: "Affidavit of Support", fieldName: "caosupport" },
      { id: 9, title: "Personal Bank Letter and Bank Statement", fieldName: "cpblabstatement" },
      { id: 10, title: "Sponsor’s Business / Job Proof - Business Profile", fieldName: "cjproof" },
      { id: 11, title: "Photocopy of Saving Certificate", fieldName: "cposcertificate" },
      { id: 12, title: "Property Documents", fieldName: "cpdocs" },
      { id: 13, title: "Property Valuation Letter", fieldName: "cpvl" },
      { id: 14, title: "Tax Return", fieldName: "ctreturns" },
      { id: 15, title: "Family B Form", fieldName: "cbform" },
      { id: 16, title: "Birth Certificate", fieldName: "cbcerti" },
      { id: 17, title: "FRC", fieldName: "cfrc" },
      { id: 18, title: "Photographs", fieldName: "cphotographs" },
      { id: 19, title: "NIC Student and Parents", fieldName: "cnicsandp" },
      { id: 20, title: "Police Certificate", fieldName: "cpolicec" },
      { id: 21, title: "Polio Certificate", fieldName: "cpolioc" },
      { id: 22, title: "Visa Application Fee", fieldName: "cvafee" },
      { id: 23, title: "CAQ", fieldName: "ccaq" },
    ],
  },
  {
    country: "United Kingdom",
    documents: [
      { id: 1, title: "Passport", fieldName: "ukpassport" },
      { id: 2, title: "CAS – Confirmation of Acceptance of Studies", fieldName: "ukcas" },
      { id: 3, title: "Accommodation proof", fieldName: "ukaproof" },
      { id: 4, title: "Academics documents original", fieldName: "ukacademics" },
      { id: 5, title: "IELTS", fieldName: "ukilets" },
      { id: 6, title: "1 original of Self / Sponsor’s Personal Bank Statement & Bank Letter", fieldName: "ukbankletter" },
      { id: 7, title: "Affidavit of Support", fieldName: "ukaffidavit" },
      { id: 8, title: "Sponsor Business / Job Proof", fieldName: "ukjproof" },
      { id: 9, title: "Family B Form", fieldName: "ukfamilybform" },
      { id: 10, title: "Birth Certificate", fieldName: "ukbcertificate" },
      { id: 11, title: "FRC", fieldName: "ukfrc" },
      { id: 12, title: "Self and Sponsors’ copy of CNIC", fieldName: "ukselfcnic" },
      { id: 13, title: "TB-Screening Test Certificate", fieldName: "uktbtest" },
    ],
  },
];

export const checkCountry = (visaView) => {
  const countryCode = visaView?.VisaInfo?.appData?.country?.label || "Unknown";
  return countryDocumentNames[countryCode] || "Document Checklist";
};

export const getCountryDocuments = (visaView) => {
  const countryCode = visaView?.VisaInfo?.appData?.country?.label || "UNKNOWN";
  const countryDoc = countryDocuments?.find((doc) => doc?.country === countryCode);
  return countryDoc ? countryDoc?.documents : [];
};

export const loadEditDatabaseFiles = (visaView) => {
  // console.log("visaView", visaView);
  if (!visaView || !visaView?.visaDocs) return {};
  return { ...visaView?.visaDocs };
};
