import React, { useEffect, useState } from "react";
import { CRUDFunction } from "../../../reduxCURD/container";
import { Box } from "@mui/material";
import VisaTopDetail from "./VisaTopDetail";
import VisaApprovalForCompilance from "./VisaApprovalForCompilance";
import { curdActions } from "../../../reduxCURD/actions";
import VisaCompliance from "./VisaCompliance";
import { VisaAccessMethods } from "../../../BussinessLogic/Visa/VisaAccessMethod";
import DocumentCheckList from "./DocumentCheckList";
import { fileUploads } from "../../../reduxRelated/actions/fileActions";

const VisaProfile = (props) => {
  console.log("props", props);
  // this state is for whether we want to go to compliance stage or not
  const [approvalStatus, setApprovalStatus] = useState("");
  //state for document uploades in doucment checklist to store name of file

  //get Single visa Profile data
  const getSingleVisa = () => {
    props?.getVisaview(props?.match?.params?.id);
  };

  useEffect(() => {
    getSingleVisa();
  }, []);

  // get ALL uSERS
  const getUsers = () => {
    props?.getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  const visaFileUploading = (multiArrayFiles) => {
    // console.log("multiarrayfiles", multiArrayFiles);

    if (multiArrayFiles) {
      // console.log("multiarrayfiles on onSubmit", multiArrayFiles);
      multiArrayFiles.map((mf) => props.fileUploads(mf, "/uploadsingle?stdId=" + props?.visaview?.studentId));
    }
  };

  return (
    <Box sx={{ marginTop: "120px" }}>
      <VisaTopDetail updateVisa={props?.updateVisaview} visaView={props?.visaview} users={props?.users} />
      {!props?.visaview?.status && (
        <VisaApprovalForCompilance
          updateVisa={props?.updateVisaview}
          visaView={props?.visaview}
          setApprovalStatus={setApprovalStatus}
          approvalStatus={approvalStatus}
        />
      )}

      {props?.visaview?.status &&
        props?.visaview?.complianceApproval === "unapproved" &&
        (VisaAccessMethods?.visaOfficerRoleCheck() ||
          VisaAccessMethods?.adminRoleCheck() ||
          VisaAccessMethods?.managementRoleCheck() ||
          VisaAccessMethods?.branchManagerRoleCheck()) && <VisaCompliance updateVisa={props?.updateVisaview} visaView={props?.visaview} />}

      {(props?.visaview?.complianceApproval === "approved" || props?.visaview?.visaOfficerForm?.interviewResult === "pass") && (
        <DocumentCheckList
          updateVisa={props?.updateVisaview}
          visaFileUploading={visaFileUploading}
          visaView={props?.visaview}
          users={props?.users}
        />
      )}
    </Box>
  );
};

let variableList = {
  userReducer: {
    users: [],
  },
};
const getUsers = curdActions.getUsers;

let actionOptions = {
  create: true,
  update: true,
  delete: true,
  read: true,
  others: {
    fileUploads,
    getUsers,
  },
};
let addditionalREducer = ["userReducer"];

let VisaContainer = CRUDFunction(VisaProfile, "visaview", actionOptions, variableList, addditionalREducer, "visa");
export default VisaContainer;
