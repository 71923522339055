import { Box, Typography } from "@mui/material";
import DocumentCountryWise from "./DocumentCountryWise";
import React, { useState } from "react";

const DocumentCheckList = ({ visaView, visaFileUploading, updateVisa }) => {
  return (
    <Box sx={{ padding: 3, borderRadius: 2 }}>
      <DocumentCountryWise visaView={visaView} visaFileUploading={visaFileUploading} updateVisa={updateVisa} />
    </Box>
  );
};

export default DocumentCheckList;
