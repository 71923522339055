import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const VisaCard = (visas) => {
  return (
    <Box
      sx={{
        p: 4,
        marginTop: "4rem",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "3.5rem 1.5rem ",
        "@media (max-width: 425px)": {
          p: 2,
        },
      }}
    >
      {visas?.visas?.map((visa, index) => (
        <Card
          key={index}
          sx={{
            width: 345,
            overflow: "initial",
            [`@media (max-width: 455px)`]: {
              width: 300,
            },
            [`@media (max-width: 390px)`]: {
              width: 270,
            },
          }}
        >
          <CardContent>
            <Stack spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px" }}>
              <Stack>
                <Typography variant="subtitle2" sx={{ textAlign: "center", fontSize: 14, color: "#1968AD" }}>
                  Student Id #:{visa?.studentId}
                </Typography>
              </Stack>
            </Stack>

            <>
              <Box>
                <Typography variant="subtitle2" sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>
                  Application Id #: {visa?.applicationId}
                </Typography>
              </Box>
              <Stack my={2} direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" color="#1968AD">
                  Branch
                </Typography>
                <span style={{ color: "1968AD", fontSize: "0.8rem" }}>
                  {/* {task?.branch} */}
                  SFK
                </span>
              </Stack>
            </>
            {/* )} */}
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Link to={`/visa/${visa?._id}`} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#1968AD",
                  width: "75px",
                  height: "28px",
                  marginRight: "30px",
                  marginBottom: "30px",
                  fontSize: "12px",
                  fontWeight: 300,
                  letterSpacing: "1.25px",
                  fontStyle: "normal",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                View
              </Button>
            </Link>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default VisaCard;
