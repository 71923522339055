import React, { useState } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox } from "@mui/material";
import { Visibility, Replay } from "@mui/icons-material";
import { getCountryDocuments, checkCountry, loadEditDatabaseFiles } from "../../../BussinessLogic/Visa/CheckCountry";
import { getLoggedInUser } from "../../../utils/loggedInUser";
const DocumentCountryWise = ({ visaView, visaFileUploading, updateVisa }) => {
  //state for files in visaDocs

  const [uploadedDocuments, setUploadedDocuments] = useState(loadEditDatabaseFiles(visaView));
  const [files, setFiles] = useState({});
  //   console.log("visaView", visaView);

  //checkcountr.js file in which it has different methods to check for country and their docs
  const documentChecklistName = checkCountry(visaView);
  const documents = getCountryDocuments(visaView);

  // user get from getLoggedInUser

  const user = getLoggedInUser();

  //file uploading documents method locally
  const handleFileUpload = (file, fieldName) => {
    const newFile = {
      fileName: file.name,
      uploaded: {
        user: { id: user?._id, name: user?.name, email: user?.email },
        date: new Date(),
      },
      viewed: {
        user: { id: user?._id, name: user?.name, email: user?.email },
        value: null,
      },
      verified: {
        user: { id: user?._id, name: user?.name, email: user?.email },
        value: null,
      },
      revert: {
        user: { id: user?._id, name: user?.name, email: user?.email },
        value: null,
      },
    };
    setUploadedDocuments((prevState) => {
      const updatedDocuments = {
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          files: [...(prevState[fieldName]?.files || []), newFile],
        },
      };

      updateVisa(
        {
          visaDocs: updatedDocuments,
        },
        visaView?._id,
      );

      return updatedDocuments;
    });

    setFiles((prevState) => {
      const updatedFiles = {
        ...prevState,
        [fieldName]: [...(prevState[fieldName] || []), file],
      };

      // Generate the multiArrayFiles from the updated files state
      let multiArrayFiles = [];
      Object.keys(updatedFiles).forEach((fKey) => {
        updatedFiles[fKey].forEach((fil) => {
          multiArrayFiles.push(fil);
        });
      });

      // console.log("multi array files", multiArrayFiles);
      visaFileUploading(multiArrayFiles);

      return updatedFiles;
    });
  };
  // console.log("uploaded doucments", uploadedDocuments);
  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        {documentChecklistName}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Documents</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Revert</TableCell>
              <TableCell>Verify</TableCell>
              <TableCell>Upload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((doc) => (
              <TableRow key={doc?.id}>
                <TableCell>{doc?.title}</TableCell>
                <TableCell>
                  {uploadedDocuments[doc?.fieldName]?.files?.map((fileObj, index) => <div key={index}>{fileObj?.fileName}</div>) ||
                    "No files uploaded"}
                </TableCell>
                <TableCell>
                  <Visibility />
                </TableCell>
                <TableCell>
                  <Replay />
                </TableCell>

                <TableCell>
                  <Checkbox
                  // key={index}
                  // checked={verifiedDocs[doc?.fieldName]?.[index] || false}
                  // onChange={(event) => handleVerifyChange(event, doc?.fieldName, index)}
                  />
                </TableCell>
                <TableCell>
                  <Button variant="contained" component="label">
                    Upload
                    <input type="file" hidden onChange={(event) => handleFileUpload(event?.target?.files[0], doc?.fieldName)} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DocumentCountryWise;
