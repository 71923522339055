import React, { useState } from "react";
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";

const VisaApprovalForCompilance = ({ visaView, updateVisa, setApprovalStatus, approvalStatus }) => {
  const handleApprovalChange = (event) => {
    setApprovalStatus(event.target.value);
  };

  const handleSave = () => {
    updateVisa && updateVisa({ complianceApproval: approvalStatus, status: true }, visaView._id);
  };

  return (
    <Box sx={{ padding: 3, borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>
        Compliance Approval
      </Typography>
      <Typography variant="body1" gutterBottom>
        You can approve this to go direct to the document checklist, and on unapproved, you will go to the compliance stage.
      </Typography>
      <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
        <InputLabel id="approval-label">Approval Status</InputLabel>
        <Select
          labelId="approval-label"
          id="approval-select"
          value={approvalStatus}
          onChange={handleApprovalChange}
          label="Approval Status"
        >
          <MenuItem value="approved">Approved</MenuItem>
          <MenuItem value="unapproved">Unapproved</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleSave}>
        Save
      </Button>
    </Box>
  );
};

export default VisaApprovalForCompilance;
