import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box,
  Typography,
  Dialog,
  Autocomplete,
  Grid,
  Tooltip,
  Modal,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  IconButton,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { DialogTitle, DialogContent, DialogActions, TextField, InputBase } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory, useParams } from "react-router-dom";
import { DatePicker } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { guid } from "../../utils/cart";
import AddQuery from "./AddQuery";
import { getLoggedInUser } from "../../utils/loggedInUser";
import LinkIcon from "@mui/icons-material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { branchesOptions } from "../../Data/BranchesOptions";
import { loadUserOptions } from "../../BussinessLogic/Leads/LeadAssignTo";
import { dialingCodeOptions } from "../../Data/DialingCodes";
import { namePrefixOptions } from "../../Data/NamePrefix";
import EditIcon from "@mui/icons-material/Edit";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import LeadCampaignModal from "./LeadCampaignModal";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CollaboratorDropdown from "./CollaboratorDropdown";

const SubmissionMaster = ({
  setModal,
  location,
  createLead,
  leads,
  getLeads,
  updateLead,
  getProceedData,
  proceedData,
  getLead,
  users,
  leadEditData,
  setleadEditData,
  setEdit,
  edit,
  onDelete,
  allLeadData,
  LeadDataforCampaign,
  leadDataToCampaign,
  leadEditComment,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [infoEmail, setEmail] = useState("");
  const [cellNo, setCellNumber] = useState("");
  const [homeNo, setHouseNumber] = useState("");
  const [dob, setDate] = useState("");
  const [dialingCode, setDialingCode] = useState("");
  const [namePrefix, setNamePrefix] = useState("");
  const [branchName, setBranchName] = useState(null);
  const [education, setEducation] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [source, setSource] = useState("");
  const [answers, setAnswers] = useState({});
  const [searchText, setSearchText] = useState("");
  const [leadId, setLeadId] = useState(-1);
  const [open, setOpen] = React.useState(false);
  const [StatusleadId, setStatusleadId] = useState(-1);
  const [CampaignleadId, setCampaignleadId] = useState(-1);
  const [StatusModal, setStatusModal] = useState(false);
  const [CampaignModal, setCampaignModal] = useState(false);
  const [ScoreModal, setScoreModal] = useState(false);
  const [selectedScore, setSelectedScore] = useState("");
  const [SelectedCampaign, setSelectedCampaign] = useState("");

  const [selectedUsers, setSelectedUsers] = useState([]);

  // console.log("allleaddata", SingleLeadData);

  const user = getLoggedInUser();
  // console.log(users)

  useEffect(() => {
    if (leadEditData?.manualForm) {
      setFirstName(leadEditData?.manualForm?.firstName || "");
      setMiddleName(leadEditData?.manualForm?.middleName || "");
      setLastName(leadEditData?.manualForm?.lastName || "");
      setHouseNumber(leadEditData?.manualForm?.homeNo || "");
      setCellNumber(leadEditData?.manualForm?.cellNo || "");
      setEducation(leadEditData?.manualForm?.education || "");
      setInstituteName(leadEditData?.manualForm?.instituteName || "");
      setEmail(leadEditData?.manualForm?.infoEmail || "");
      setNamePrefix(namePrefixOptions.find((option) => option.label === leadEditData?.manualForm?.namePrefix?.label) || null);
      setDialingCode(dialingCodeOptions.find((option) => option.label === leadEditData?.manualForm?.dialingCode?.label) || null);
      setDate(leadEditData?.manualForm?.dob || "");
      setBranchName(branchesOptions.find((option) => option.value === leadEditData?.manualForm?.branchName?.value) || null);
    }
  }, [leadEditData?.manualForm]);

  const resetFormFields = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setCellNumber("");
    setHouseNumber("");
    setDate("");
    setDialingCode("");
    setNamePrefix("");
    setBranchName(null);
    setEducation("");
    setInstituteName("");
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    resetFormFields();
  };

  const handleCloseModal = () => {
    setModal(false);
    setOpenModal(false);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setLeadId(-1);
  };

  // Modal For Lead Status
  const StatusModalOpen = () => setStatusModal(true);

  const StatusModalClose = () => {
    setStatusModal(false);
    setStatusleadId(-1);
  };

  // For InProcess Status
  const handleProcessLead = async () => {
    await updateLead({ isProcess: true, isClosed: false }, StatusleadId);
    getLeads();
    StatusModalClose();
  };

  // For Closed Status
  const handleCloseLead = async () => {
    await updateLead({ isClosed: true, isProcess: false }, StatusleadId);
    getLeads();
    StatusModalClose();
  };

  // Modal For Lead Campaign
  const CampaignModalOpen = () => setCampaignModal(true);

  const CampaignModalClose = () => {
    setCampaignModal(false);
    setCampaignleadId(-1);
  };

  const handleCampaign = async (camp) => {
    setSelectedCampaign(camp);
    await updateLead({ LeadCampaign: camp }, CampaignleadId);
    getLeads();
    CampaignModalClose();
  };

  // Modal For Lead Score Board
  const ScoreModalOpen = () => setScoreModal(true);

  const ScoreModalClose = () => {
    setScoreModal(false);
    setLeadId(-1);
  };

  const handleScoreChange = async (newScore) => {
    setSelectedScore(newScore);
    await updateLead({ LeadScore: newScore }, leadId);
    getLeads();
    ScoreModalClose();
  };

  function getCurrentTime() {
    const now = new Date();
    const formatTime12Hour = (date) => {
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }
      return `${hours}:${minutes}`;
    };
    const formattedTime = formatTime12Hour(now);
    return formattedTime;
  }

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  const onSend = () => {
    const time = getCurrentTime();
    const date = getCurrentDate();
    const data = {
      time: time,
      date: date,
      purpose: "Edit",
      content: `${user?.name} Edit this lead`,
    };
    leadEditComment(data, allLeadData?._id);
  };

  const handleFormSubmit = () => {
    // if (leadEditData?.manualForm) {
    //   updateLead({
    //     manualForm: {},
    //   });
    // } else {
    // }
    const leadData = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      infoEmail: infoEmail,
      cellNo: cellNo,
      homeNo: homeNo,
      dob: dob,
      dialingCode: dialingCode,
      namePrefix: namePrefix,
      branchName: branchName,
      education: education,
      instituteName: instituteName,
      source: "manual",
    };

    const ManualForm = {
      manualForm: { ...leadData, purpose: "Created", content: `${user?.name} generated this lead` },
    };

    if (user?.role === "Counselor") {
      ManualForm.manualForm.assignTo = { id: user._id, name: user.name, email: user.email };
    }
    if (leadEditData?.manualForm) {
      const { _id } = leadEditData;
      updateLead(ManualForm, _id);
      onSend();
    } else {
      // Otherwise, create a new lead
      createLead(ManualForm);
    }

    // console.log(ManualForm);

    // Clear form inputs
    resetFormFields();

    // Close the modal
    handleCloseModal();
    getLeads();
  };

  const tableColumns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "leadstatus",
      headerName: "Lead Status",
      width: 170,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit Status" placement="top">
              <IconButton disabled={cellValues?.row?.data.LeadMoveToQuery}>
                <FilterTiltShiftIcon
                  onClick={() => {
                    StatusModalOpen();
                    setStatusleadId(cellValues?.row?.data?._id);
                  }}
                  sx={{
                    cursor: "pointer",
                    color:
                      cellValues?.row?.data.isProcess === true
                        ? "Orange"
                        : cellValues?.row?.data.isClosed === true
                        ? "Red"
                        : cellValues?.row?.data.LeadMoveToQuery === true
                        ? "Green"
                        : "Grey",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>{cellValues.value}</Typography>
          </Box>
        );
      },
    },
    { field: "owner", headerName: "Owner", width: 150 },
    { field: "branch", headerName: "Branch", editable: true, width: 150 },
    { field: "namePrefix", headerName: "Name Prefix", width: 150 },
    { field: "fname", headerName: "First Name", width: 150 },
    { field: "mname", headerName: "Middle Name", width: 150 },
    { field: "lname", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Emails", width: 150 },
    { field: "source", headerName: "Source", width: 150 },
    { field: "mobile", headerName: "Mobile No", width: 150 },
    { field: "dc", headerName: "Dialing Code", width: 150 },
    { field: "hno", headerName: "House No", width: 150 },
    { field: "education", headerName: "Education", width: 150 },
    { field: "institute", headerName: "Institute Name", width: 150 },
    { field: "dob", headerName: "DOB", width: 120 },
    {
      field: "leadcampaign",
      headerName: "Lead Campaign",
      width: 160,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit Campaign" placement="top">
              <IconButton disabled={cellValues?.row?.data.LeadMoveToQuery}>
                <PersonSearchIcon
                  color="primary"
                  onClick={() => {
                    LeadDataforCampaign(cellValues?.row);
                    CampaignModalOpen();
                    setCampaignleadId(cellValues?.row?.data?._id);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>{cellValues.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "leadScore",
      headerName: "Lead Score",
      width: 160,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit Score" placement="top">
              <IconButton disabled={cellValues?.row?.data.LeadMoveToQuery}>
                {cellValues?.value === "Cold Lead" && (
                  <AcUnitIcon
                    sx={{
                      color: cellValues?.value === "Cold Lead" ? "rgb(35,115,253)" : "Grey",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      ScoreModalOpen();
                      setLeadId(cellValues?.row?.data?._id);
                    }}
                  />
                )}
                {cellValues?.value === "Hot Lead" && (
                  <LocalFireDepartmentIcon
                    sx={{
                      color: "rgb(234,65,3)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      ScoreModalOpen();
                      setLeadId(cellValues?.row?.data?._id);
                    }}
                  />
                )}
                {cellValues?.value !== "Cold Lead" && cellValues?.value !== "Hot Lead" && (
                  <AcUnitIcon
                    sx={{
                      color: "Grey",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      ScoreModalOpen();
                      setLeadId(cellValues?.row?.data?._id);
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>{cellValues.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 230,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="View" placement="top">
              <RemoveRedEyeIcon
                color="primary"
                sx={{ mr: 0.5, cursor: "pointer" }}
                onClick={() => {
                  // console.log("cellValues", cellValues);
                  getLead(cellValues?.row);
                }}
              />
            </Tooltip>

            {cellValues?.row?.data.LeadMoveToQuery === false ? (
              <Tooltip title="Edit Lead" placement="top">
                <Button
                  onClick={() => {
                    setleadEditData(cellValues?.row?.data);
                    setOpenModal(true);
                  }}
                  sx={{
                    p: 0,
                    mr: "-20px !important",
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            ) : null}

            <Tooltip title="Delete Lead" placement="top">
              <Button
                onClick={() => {
                  onDelete({ _id: cellValues?.row?.data?._id });
                }}
                sx={{
                  p: 0,
                  mr: "-20px !important",
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>

            {cellValues?.row?.data.LeadMoveToQuery === false ? (
              <Tooltip title="Proceed to Query" placement="top">
                <NavigateNextIcon
                  fontSize="large"
                  color="primary"
                  sx={{ cursor: "pointer", marginLeft: "10px", marginRight: "-5px" }}
                  onClick={() => {
                    setModal(true);
                    getProceedData(cellValues?.row);
                  }}
                />
              </Tooltip>
            ) : null}

            {cellValues?.row?.data.LeadMoveToQuery === false ? (
              <Box>
                <Tooltip
                  title="Assign to"
                  onClick={() => {
                    handleOpen();
                    setLeadId(cellValues?.row?.data?._id);
                    // setSingleLeadData(cellValues?.row?.data);
                  }}
                  placement="top"
                >
                  <LinkIcon
                    fontSize="large"
                    color="primary"
                    sx={{
                      cursor: "pointer",
                      marginLeft: "15px",
                      marginTop: "10px",
                    }}
                  />
                </Tooltip>
              </Box>
            ) : null}
          </Box>
        );
      },
    },
  ];

  const manualData = leads?.flatMap((lead, index) => {
    const uID = guid();
    const counselorsNames = lead?.assignTo ? lead.assignTo.map((person) => person?.name || "N/A").join(", ") : "N/A";
    if (lead?.manualForm) {
      return {
        id: index,
        leadstatus:
          lead?.isProcess === true
            ? "In Process"
            : lead?.isClosed === true
            ? "Lead Closed"
            : lead?.LeadMoveToQuery === true
            ? "Moved to Query"
            : "Not Started" || "N.A",
        owner: counselorsNames,
        branch: lead?.manualForm?.branchName?.value || "N.A",
        namePrefix: lead?.manualForm?.namePrefix?.label || "N.A",
        fname: lead?.manualForm?.firstName || "N/A",
        mname: lead?.manualForm?.middleName || "N/A",
        lname: lead?.manualForm?.lastName || "N/A",
        mobile: lead?.manualForm?.cellNo || "N/A",
        dc: lead?.manualForm?.dialingCode?.label || "N/A",
        hno: lead?.manualForm?.homeNo || "N/A",
        dob: lead?.manualForm?.dob || "N/A",
        education: lead?.manualForm?.education || "N/A",
        institute: lead?.manualForm?.instituteName || "N/A",
        leadScore: lead?.LeadScore || "N/A",
        leadcampaign: lead?.LeadCampaign || "N/A",
        email: lead?.manualForm?.infoEmail || "N/A",
        source: lead?.manualForm ? "Manual" : "Google Form",
        actions: "actions",
        data: lead,
      };
    }

    return [];
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };

  // const handleOptionClick = async (user) => {
  //   // console.log(user, leadId);
  //   // updateLead
  //   const assignToArray = [{ id: user._id, name: user.name, email: user.email }];
  //   await updateLead({ assignTo: assignToArray }, leadId);
  //   getLeads();
  //   handleClose();
  // };

  const handleOptionClick = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.some((u) => u.id === user._id)) {
        return prevSelectedUsers.filter((u) => u.id !== user._id);
      } else {
        return [...prevSelectedUsers, { id: user._id, name: user.name, email: user.email }];
      }
    });
  };
  const handleConfirm = async () => {
    await updateLead({ assignTo: selectedUsers }, leadId);
    getLeads();
    handleClose();
  };

  // For Handleing Filter Method in Manual Leads
  const [filterModel, setFilterModel] = useState({ items: [] });

  const handleCustomFilter = () => {
    const newFilterModel = {
      items: [
        {
          columnField: "mobile",
          operatorValue: "equals",
          value: location.search.substring(1),
        },
      ],
    };
    setFilterModel(newFilterModel);
  };

  useEffect(() => {
    handleCustomFilter();
  }, [location]);

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Collaborator
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-checkbox-label">Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={selectedUsers}
              renderValue={(selected) => selected.map((user) => user.name).join(", ")}
              input={<OutlinedInput label="Assigne Name" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
            >
              {users
                .filter((user) => user?.role === "Counselor" || user?.email === "sfh@successfactor.org")
                .map((user) => (
                  <MenuItem key={user._id} value={user} onClick={() => handleOptionClick(user)}>
                    <Checkbox checked={selectedUsers.some((u) => u.id === user._id)} />
                    <ListItemText primary={user.name} />
                  </MenuItem>
                ))}
            </Select>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button variant="contained" sx={{ width: "90%" }} onClick={handleConfirm}>
                Confirm
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button variant="contained" sx={{ mr: 2, mb: 2 }} onClick={handleOpenModal}>
            Add
          </Button>
        </Box>

        <Box sx={{ mx: 2 }}>
          <DataGrid
            sx={{
              height: `700px`,
            }}
            rows={manualData}
            columns={tableColumns}
            filterModel={filterModel}
            onFilterModelChange={(x) => setFilterModel(x)}
          />
        </Box>
      </Box>

      {/* Lead Status Modal */}
      <Modal open={StatusModal} onClose={StatusModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Lead Status
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label"> Lead Status</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
              input={<OutlinedInput label="Lead Status" />}
            >
              <MenuItem onClick={handleProcessLead}>In Process</MenuItem>
              <MenuItem onClick={handleCloseLead}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>

      {/* Lead Campaign Modal */}
      <Modal
        open={CampaignModal}
        onClose={CampaignModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <LeadCampaignModal
            handleCampaign={handleCampaign}
            SelectedCampaign={SelectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            updateLead={updateLead}
            allLeadData={allLeadData}
            getLeads={getLeads}
            leadDataToCampaign={leadDataToCampaign}
          />
        </Box>
      </Modal>

      {/* Lead Score Modal */}
      <Modal open={ScoreModal} onClose={ScoreModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Lead Score Board
          </Typography>
          <FormControl sx={{ mt: 2, width: 330 }}>
            <InputLabel id="demo-multiple-name-label">Lead Score Board</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={selectedScore}
              onChange={(e) => setSelectedScore(e.target.value)}
              input={<OutlinedInput label="Lead Score Board" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 160,
                  },
                },
              }}
            >
              <MenuItem value={"Hot Lead"} onClick={() => handleScoreChange("Hot Lead")}>
                Hot Lead
              </MenuItem>
              <MenuItem value={"Cold Lead"} onClick={() => handleScoreChange("Cold Lead")}>
                Cold Lead
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Modal>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>New Leads Form</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ mt: 2 }}>
              <Autocomplete
                disablePortal
                fullWidth
                id="dialing"
                margin="normal"
                variant="outlined"
                options={branchesOptions}
                value={branchName}
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option, value) => option?.value === value?.value}
                onChange={(event, newValue) => {
                  // console.log("newValue", newValue);
                  setBranchName(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Branch" />}
              />
            </Grid>

            <Grid item xs={4} sx={{ mt: 2 }}>
              <Autocomplete
                disablePortal
                fullWidth
                id="dialing"
                margin="normal"
                variant="outlined"
                options={namePrefixOptions}
                value={namePrefix}
                onChange={(event, newValue) => {
                  setNamePrefix(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Name Prefix" />}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label=" First Name"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label=" Middle Name"
                fullWidth
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Email"
                fullWidth
                value={infoEmail}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label=""
                type="date"
                fullWidth
                value={dob}
                onChange={(e) => setDate(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4} sx={{ mt: 2 }}>
              <Autocomplete
                disablePortal
                fullWidth
                id="dialing"
                margin="normal"
                variant="outlined"
                options={dialingCodeOptions}
                value={dialingCode}
                onChange={(event, newValue) => {
                  setDialingCode(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="dialing code" />}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Education"
                fullWidth
                value={education}
                onChange={(e) => setEducation(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Institute Name"
                fullWidth
                value={instituteName}
                onChange={(e) => setInstituteName(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Cell Number"
                fullWidth
                value={cellNo}
                onChange={(e) => setCellNumber(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Home Number"
                fullWidth
                value={homeNo}
                onChange={(e) => setHouseNumber(e.target.value)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <div style={{ display: "flex", marginLeft: "1rem" }}>
          <DialogActions>
            <Button onClick={handleFormSubmit} variant="contained" color="primary">
              Next
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default SubmissionMaster;
