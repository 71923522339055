import React from "react";
import { Box } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import zIndex from "@mui/material/styles/zIndex";

const WidgetContainer = ({ dropWidgets, widget, onDeleteLayout, edit }) => {
  const { setNodeRef, listeners, attributes, transform, transition } = useSortable({
    id: widget.widget,
    data: {
      type: "widget",
      widget,
    },
  });

  const style = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    }
    : undefined;

  return (
    <>
      {edit ? (
        <Box {...attributes} {...listeners} ref={setNodeRef} style={style}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            {dropWidgets[widget.widget]}
            {edit && (
              <span
                onClick={() =>
                  onDeleteLayout({
                    type: "widget",
                    id: widget.id,
                  })
                }
                style={{
                  width: "1.2rem",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  zIndex: "2",
                  cursor: "pointer",
                  marginTop: "12px"
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </span>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              position: "relative",
            }}
          >
            {dropWidgets[widget.widget]}
            {edit && (
              <span
                onClick={() =>
                  onDeleteLayout({
                    type: "widget",
                    id: widget.id,
                  })
                }
                style={{
                  width: "1.2rem",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  zIndex: "2",
                  cursor: "pointer",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </span>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default WidgetContainer;
