import { getLoggedInUser } from "../../utils/loggedInUser";

export const VisaAccessMethods = {
  visaOfficerRoleCheck: () => {
    let user = getLoggedInUser();
    if (!user) return false;
    return user?.role === "Visa Officer";
  },
  adminRoleCheck: () => {
    let user = getLoggedInUser();
    if (!user) return false;
    return user?.role === "Administrator";
  },

  managementRoleCheck: () => {
    let user = getLoggedInUser();
    if (!user) return false;
    return user?.role === "Management";
  },

  branchManagerRoleCheck: () => {
    let user = getLoggedInUser();
    if (!user) return false;
    return user?.role === "Branch Manager";
  },
};
