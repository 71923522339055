import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Chart from "react-apexcharts";

const RegistrationWidget = ({ registrationData }) => {
  // console.log("registrationData", registrationData);

  const generateChartData = (data) => {
    if (!data) return { series: [], categories: [] };

    const series = [
      {
        name: "In Process",
        data: [],
      },
      {
        name: "Closed",
        data: [],
      },
      {
        name: "Moved To Processing",
        data: [],
      },
    ];

    const categories = [];

    Object?.keys(data)?.forEach((Branch) => {
      const branchData = data[Branch];
      // console.log("branchData", branchData);
      categories.push(Branch);
      // console.log("categories", categories);
      series[0]?.data?.push(branchData?.inProcessCountRegistration);
      series[1]?.data?.push(branchData?.closedCountRegistration);
      series[2]?.data?.push(branchData?.moveToProcessingCount);
    });
    return { series, categories };
  };

  const { series, categories } = generateChartData(registrationData);

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: "Registration",
      },
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: "top",
    },
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" align="center">
              Registration Status
            </Typography>
            <Chart options={chartOptions} series={series} type="bar" height={350} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RegistrationWidget;
