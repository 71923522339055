import { Divider, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Notification from "../../Components/Common/Notification";
import ApplicationForm from "./ApplicationForm/ApplicationForm";
import StudentDetails from "./StudentDetails";
import TabPanel from "./TabPanel/TabPanel";
import StudentForm from "../Student/StudentForm";
import ViewProfile from "./ViewProfile";
import useMediaQuery from "@mui/material/useMediaQuery";
import StudentProfileUI from "./StudentProfileUI";
import Task from "./Task";
import WorkingSheetGrid from "./WorkingSheetGrid";
import StudentProfileData from "./StudentProfileData";
import WorkingSheetForm from "./WorkingSheetForm/WorkingSheetForm";
import DocumentBrowser from "./DocumentBrowser";
import Grid from "@mui/material/Grid";
import StudentTask from "./TabPanel/Status/Templates/StudentTask";
import StudentTimeline from "./TabPanel/Status/Templates/StudentTimeline";
import StudentData from "./TabPanel/Status/Templates/StudentData";

const StudentProfile = ({
  onSubmit,
  edit,
  setEdit,
  onDelete,
  showForm,
  setShowForm,
  onEdit,
  editData,
  applications,
  onUpdate,
  appDetail,
  setAppDetail,
  student,
  users,
  updateStudent,
  setStudentForm,
  studentForm,
  studentSubmit,
  studentData,
  setStudentData,
  existingStudentData,
  fileUpload,
  location,
  workingSheetForm,
  setWorkingSheetForm,
  workingSheets,
  getProceedData,
  sheetEditData,
  setSheetEditData,
  studentTimelineUpdate,
}) => {
  // console.log("student", student);
  // const [appDetail, setAppDetail] = useState({
  //   show: false,
  //   values: [],
  // });

  const [applicationFormData, setApplicationFormData] = useState([]);
  const [applicationFormFiles, setApplicationFormFiles] = useState({});
  const [applicationDataFinal, setApplicationDataFinal] = useState([]);
  // const data = appDetail.values[0];

  const [successOpen, setSuccessOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }));

  const isMobile = useMediaQuery("(max-width:700px)");
  const tabStyle1 = {
    fontSize: "16px",
    fontWeight: "bold",
    padding: "12px 16px",
    color: "grey",
  };
  const tabStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "grey",
  };

  const activeTabStyle = {
    ...tabStyle,
    color: "rgba(25, 104, 173, 1)",
    borderBottom: "2px solid rgba(25, 104, 173, 1)", // Border style for active tab
  };
  //  console.log("applications" , applications);
  return (
    <Box>
      <Notification open={successOpen} setOpen={setSuccessOpen} text="Data Submitted Successfully" />
      <Notification open={deleteOpen} setOpen={setDeleteOpen} variant="error" text="Data removed from the table" />
      <StudentDetails
        handleOpen={handleOpen}
        setStudentForm={setStudentForm}
        student={student}
        users={users}
        updateStudent={updateStudent}
        studentTimelineUpdate={studentTimelineUpdate}
      />

      <div>
        {isMobile ? (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Select
              sx={{ width: "200px", marginBottom: "1rem", marginLeft: "1rem" }}
              value={selectedTab}
              onChange={(event) => setSelectedTab(event.target.value)}
            >
              <MenuItem value={0}>Info</MenuItem>
              <MenuItem value={1}>Summary</MenuItem>
              <MenuItem value={2}>Working Sheet</MenuItem>
              <MenuItem value={3}>Documents</MenuItem>
              <MenuItem value={4}>Tasks</MenuItem>
              <MenuItem value={5}>Applications</MenuItem>
            </Select>
          </div>
        ) : (
          <>
            {selectedTab === 3 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  fontSize: "1rem",
                  marginLeft: "2rem",
                  gap: "2rem",
                  marginRight: "2rem",
                  borderBottom: "1px solid rgba(217, 217, 217, 1)",
                  marginBottom: "20px",
                }}
              >
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="mui-tabs-example">
                  <Tab style={selectedTab === 0 ? activeTabStyle : tabStyle1} label="Info" />
                  <Tab style={selectedTab === 1 ? activeTabStyle : tabStyle1} label="Summary" />
                  <Tab style={selectedTab === 2 ? activeTabStyle : tabStyle1} label="Working Sheet" />
                  <Tab style={selectedTab === 3 ? activeTabStyle : tabStyle1} label="Documents" />
                  <Tab style={selectedTab === 4 ? activeTabStyle : tabStyle1} label="Tasks" />
                  <Tab style={selectedTab === 5 ? activeTabStyle : tabStyle1} label="Applications" />
                </Tabs>
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    fontSize: "1rem",
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    gap: "2rem",
                    borderBottom: "1px solid rgba(217, 217, 217, 1)",
                    marginBottom: "20px",
                  }}
                >
                  <Tabs value={selectedTab} onChange={handleTabChange} aria-label="mui-tabs-example">
                    <Tab style={selectedTab === 0 ? activeTabStyle : tabStyle} label="Info" />
                    <Tab style={selectedTab === 1 ? activeTabStyle : tabStyle} label="Summary" />
                    <Tab style={selectedTab === 2 ? activeTabStyle : tabStyle} label="Working Sheet" />
                    <Tab style={selectedTab === 3 ? activeTabStyle : tabStyle} label="Documents" />
                    <Tab style={selectedTab === 4 ? activeTabStyle : tabStyle} label="Tasks" />
                    <Tab style={selectedTab === 5 ? activeTabStyle : tabStyle} label="Applications" />
                  </Tabs>
                </div>
              </>
            )}
          </>
        )}

        {selectedTab === 0 && (
          <div>
            <Box
              sx={{
                marginLeft: "2rem",
                marginRight: "2rem",
                [`@media (max-width: 425px)`]: {
                  margin: 0,
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={4} sm={12} xs={12}>
                  <StudentData student={student} />
                  <Grid>
                    <StudentTask updateStudent={updateStudent} student={student} />
                  </Grid>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                  <Grid>
                    <TabPanel
                      setAppDetail={setAppDetail}
                      applicationFormData={applicationFormData}
                      applicationDataFinal={applicationDataFinal}
                      appDetail={appDetail}
                      setApplicationDataFinal={setApplicationDataFinal}
                      applications={applications}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      onUpdate={onUpdate}
                      updateStudent={updateStudent}
                      setShowForm={setShowForm}
                      setDeleteOpen={setDeleteOpen}
                      edit={edit}
                      setEdit={setEdit}
                      student={student}
                      fileUpload={fileUpload}
                      location={location}
                      setWorkingSheetForm={setWorkingSheetForm}
                      studentTimelineUpdate={studentTimelineUpdate}
                      selectedTab={selectedTab}
                    />
                  </Grid>

                  <Grid>
                    <StudentTimeline student={student} users={users} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <ViewProfile student={student} open={open} handleClose={handleClose} selectedTab={selectedTab} />
          </div>
        )}
        {selectedTab === 2 && (
          <div>
            <WorkingSheetGrid
              workingSheets={workingSheets}
              getProceedData={getProceedData}
              setShowForm={setShowForm}
              setSheetEditData={setSheetEditData}
              fileUpload={fileUpload}
              location={location}
              student={student}
            />
          </div>
        )}
        {selectedTab === 3 && (
          <Box>
            <DocumentBrowser applications={applications} student={student} />
          </Box>
        )}
        {selectedTab === 4 && (
          <div>
            {/* <Task /> */}
            <Grid>
              <StudentTask updateStudent={updateStudent} student={student} />
            </Grid>
            <Grid>
              <StudentTimeline student={student} users={users} />
            </Grid>
          </div>
        )}
        {selectedTab === 5 && (
          <div>
            <TabPanel
              selectedTab={selectedTab}
              doNotShowGrid
              setAppDetail={setAppDetail}
              applicationFormData={applicationFormData}
              applicationDataFinal={applicationDataFinal}
              appDetail={appDetail}
              setApplicationDataFinal={setApplicationDataFinal}
              applications={applications}
              onDelete={onDelete}
              onEdit={onEdit}
              onUpdate={onUpdate}
              updateStudent={updateStudent}
              setShowForm={setShowForm}
              setDeleteOpen={setDeleteOpen}
              edit={edit}
              setEdit={setEdit}
              student={student}
              fileUpload={fileUpload}
              location={location}
              setWorkingSheetForm={setWorkingSheetForm}
              studentTimelineUpdate={studentTimelineUpdate}
            />
          </div>
        )}
      </div>

      {/* <div style={{ display: "flex", justifyContent: "start", fontSize: "1rem", marginLeft: "2rem", gap: "2rem" }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="mui-tabs-example">
          <Tab label="Info" />
          <Tab label="Summary" />
          <Tab label="Working Sheet" />
          <Tab label="Documents" />
          <Tab label="Tasks" />
        </Tabs>
      </div>

      {selectedTab === 0 && (
        <div>
          <StudentProfileUI />
        </div>
      )}
      {selectedTab === 1 && (
        <div>
          <StudentProfileUI />
        </div>
      )}
      {selectedTab === 2 && (
        <div>
          <StudentProfileUI />
        </div>
      )}

      {selectedTab === 3 && (
        <div>
          <StudentProfileUI />
        </div>
      )}

      {selectedTab === 4 && (
        <div>
          <StudentProfileUI />
        </div>
      )}

      {selectedTab === 5 && (
        <div>
          <StudentProfileUI />
        </div>
      )} */}

      {/* <TabPanel
        setAppDetail={setAppDetail}
        applicationFormData={applicationFormData}
        applicationDataFinal={applicationDataFinal}
        appDetail={appDetail}
        setApplicationDataFinal={setApplicationDataFinal}
        applications={applications}
        onDelete={onDelete}
        onEdit={onEdit}
        onUpdate={onUpdate}
        updateStudent={updateStudent}
        setShowForm={setShowForm}
        setDeleteOpen={setDeleteOpen}
        edit={edit}
        setEdit={setEdit}
        student={student}
        fileUpload={fileUpload}
        location={location}
        setWorkingSheetForm={setWorkingSheetForm}
      /> */}
      {/* {workingSheetForm && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <WorkingSheetForm setWorkingSheetForm={setWorkingSheetForm} />
        </Box>
      )} */}
      {showForm && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <ApplicationForm
            setApplicationFormData={setApplicationFormData}
            setApplicationFormFiles={setApplicationFormFiles}
            applicationFormFiles={applicationFormFiles}
            applicationFormData={applicationFormData}
            setShowForm={setShowForm}
            setSuccessOpen={setSuccessOpen}
            setApplicationDataFinal={setApplicationDataFinal}
            onSubmit={onSubmit}
            edit={edit}
            editData={editData}
            onUpdate={onUpdate}
            student={student}
            sheetEditData={sheetEditData}
            setSheetEditData={setSheetEditData}
            applications={applications}
          />
        </Box>
      )}
      {studentForm && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1212",
          }}
        >
          <StudentForm
            isEdit
            setModal={setStudentForm}
            setStudentData={setStudentData}
            studentData={studentData}
            onSubmit={studentSubmit}
            existingStudentData={existingStudentData}
          />
        </Box>
      )}
      {/* <Box>
        <ViewProfile student={student} open={open} handleClose={handleClose} />
      </Box> */}
    </Box>
  );
};

export default StudentProfile;
