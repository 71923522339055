import React from "react";
import InputForm from "../Common/Form/InputForm";
import { Typography, Box } from "@mui/material";
import Button from "@mui/material/Button";
import { branchesOptions } from "../../Data/BranchesOptions";

export const UserForm = (props) => {
  // const [selectedUser, setSelectedUser] = React.useState(props.selectedUser);
  const [selectedUser, setSelectedUser] = React.useState({
    ...props.selectedUser,
    branch: props.selectedUser?.branch || "",
  });
  const [schema, setSchema] = React.useState({});
  React.useEffect(() => {
    if (props.mode === "add") {
      setSelectedUser(null);
      setSchema({
        ...userSchema,
        ...{ role: { ...userSchema.role, options: allowedOptions(props.selfUser) } },
      });
    } else if (props.mode === "edit") {
      const userEditChange = props.mode === "edit" && selectedUser && props.selectedUser && props.selectedUser.email !== selectedUser.email;
      const userEdit = props.mode === "edit" && !selectedUser;
      if (userEditChange || userEdit) {
        setSelectedUser(props.selectedUser);
        setSchema({
          ...userEditSchema,
          ...{
            role: {
              ...userEditSchema.role,
              disabled: props.selfUser && props.selectedUser && props.selfUser.email === props.selectedUser.email ? true : false,
              options: allowedOptions(props.selfUser, props.selectedUser),
            },
          },
        });
      }
    } else {
      setSchema({});
      setSelectedUser(null);
    }
  }, [props.selectedUser, props.mode]);
  let userName = props.selectedUser ? props.selectedUser.name : "";
  let headerValue = props.mode === "add" ? "Create User" : "Edit " + userName;
  return (
    <React.Fragment>
      <InputForm
        formFieldSchema={schema}
        fields={selectedUser}
        handleClose={props.handleCancel}
        handleSave={props.handleSave}
        config={{
          customHeader: renderHeader(props.mode, props.handleDelete, headerValue),
        }}
      />
    </React.Fragment>
  );
};
const renderHeader = (mode, deleteMethod, headerValue) => {
  return (
    <React.Fragment>
      {mode && (
        <Box sx={{ display: "flex", justifyContent: "left", marginTop: 2, marginBottom: 3 }}>
          <Typography component="h1" variant="h5" item sx={{ flex: 1 }}>
            {headerValue}
          </Typography>
          {mode !== "add" && deleteMethod && (
            <Button variant="contained" color="error" onClick={deleteMethod} item>
              Delete
            </Button>
          )}
        </Box>
      )}
    </React.Fragment>
  );
};
function allowedOptions(loggedUser, selected) {
  let belowLevel = loggedUser && loggedUser.level;
  let options = [
    { text: "Administrator", value: "Administrator", level: 1 },
    { text: "Management", value: "Management", level: 2 },
    // { text: "Mentor", value: "Mentor", level: 3 },
    { text: "Branch Manager", value: "Branch Manager", level: 3 },
    { text: "Counselor", value: "Counselor", level: 3 },
    { text: "Application Officer", value: "Application Officer", level: 4 },
    { text: "Visa Officer", value: "Visa Officer", level: 5 },
    { text: "Student", value: "Student", level: 7 },
    { text: "Marketing", value: "Marketing", level: 8 },
  ];
  return options.filter((op) => (selected && selected.email === loggedUser.email ? op.level >= belowLevel : op.level > belowLevel));
}

const userSchema = {
  name: {
    name: "name",
    type: "text",
    label: "Name",
    required: true,
    value: "",
  },
  email: {
    name: "email",
    type: "email",
    label: "Email",
    value: "",
    required: true,
  },
  password: {
    name: "password",
    type: "password",
    label: "Password",
    value: "",
    required: true,
  },
  role: {
    name: "role",
    type: "select",
    label: "Role",
    value: "",
    required: true,
    options: [],
  },
  branch: {
    name: "branch",
    type: "select",
    label: "Branches",
    options: branchesOptions,
  },
};

const userEditSchema = {
  name: {
    name: "name",
    type: "text",
    label: "Name",
    required: true,
    value: "",
  },
  email: {
    name: "email",
    type: "email",
    label: "Email",
    disabled: true,
    value: "",
    required: true,
  },

  role: {
    name: "role",
    type: "select",
    label: "Role",
    value: "",
    required: true,
    disabled: false,
    options: [],
  },

  branch: {
    name: "branch",
    type: "select",
    label: "Branches",
    options: branchesOptions,
  },
};
