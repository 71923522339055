import React from "react";
import { ChonkyActions, ChonkyIconName, FileHelper, FileList, FileNavbar, FileToolbar, defineFileAction, setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { FullFileBrowser } from "chonky";
import { Box, Modal } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import folderSearch from "./folderSearch";
import { studentFileDownloadPath } from "../../utils/studentFileDownloadUtil";
import { multiFileAccessorUtil } from "../../utils/multiFileAccessorUtil";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

// const fileData = [
//   {
//     id: "0",
//     name: "Root",
//     isDir: true,
//     files: [
//       {
//         id: "1",
//         name: "Folder 1",
//         isDir: true,
//         childrenCount: 1,
//         color: "#1968ad",
//         modDate: "2020-10-20T13:13:02.620Z",
//         files: [
//           {
//             id: "111",
//             name: "Folder 2",
//             isDir: true,
//             childrenCount: 2,
//             color: "#1968ad",
//             modDate: "2020-10-20T13:13:02.620Z",
//             files: [
//               {
//                 id: "1111",
//                 name: "test file.txt",
//                 modDate: "2020-10-20T13:13:02.620Z",
//               },
//               {
//                 id: "11112",
//                 name: "image.png",
//                 modDate: "2020-10-20T13:13:02.620Z",
//               },
//             ],
//           },
//         ],
//       },
//       { id: "2", name: "Folder 2", isDir: true, color: "#1968ad", modDate: "2020-10-20T13:13:02.620Z" },
//       { id: "3", name: "Folder 3", isDir: true, color: "#1968ad", modDate: "2020-10-20T13:13:02.620Z" },
//     ],
//   },
// ];

const DocumentBrowser = (props) => {
  // console.log(props.singleApplicationData)
  const [rowColumn, setRowColumn] = useState(true);
  const documentData = props.applications
    ?.filter((item) => item?.workingSheet?.documentUpload !== undefined)
    ?.map((appItem, idx) => {
      let docFiles = [];
      // console.log("docFiles");
      for (let docField in appItem.workingSheet.documentUpload) {
        appItem.workingSheet.documentUpload[docField].map((file, index) => {
          docFiles.push({
            id: index + docField,
            name: file,
            color: "#f68b1e",
          });
        });
      }
      return {
        id: appItem._id,
        name: appItem?.workingSheet?.university?.label || "No name",
        isDir: true,
        modDate: appItem?.createdAt,
        color: "#f68b1e",
        files: docFiles,
      };
    });

  const entranceTest = multiFileAccessorUtil(props?.student?.registrationData, "entranceTest", "entranceTestUploadResult").map(
    (file, index) => ({
      id: "entranceTest" + index + "entranceTestUploadResult",
      name: file,
      color: "yellow",
    }),
  );

  const englishProficiency = multiFileAccessorUtil(props?.student?.registrationData, "englishProficiency", "uploadTestResult").map(
    (file, index) => ({
      id: "englishProficiency" + index + "uploadTestResult",
      name: file,
      color: "red",
    }),
  );

  // console.log("englishProficiency", englishProficiency);
  // console.log("entranceTest", entranceTest);

  const studentFiles = [
    {
      id: props?.student?._id,
      name: "Student files",
      isDir: true,
      modeDate: props?.student?.createdAt,
      color: "#f68b1e",
      files: [
        {
          id: "uploadRefusalLetter",
          name: props?.student?.registrationData?.uploadRefusalLetter,
          color: "black",
        },
        ...englishProficiency,
        ...entranceTest,
      ],
    },
  ];

  const fileData = [
    {
      id: "0",
      name: `${props?.student?.registrationData?.firstName}/${props?.student?.registeration}`,
      isDir: true,
      files: [...documentData, ...studentFiles],
    },
  ];

  const findFile = (data, fileId) => {
    // console.log("filesearch", data);
    for (let i = 0; i < data.length; i++) {
      const folder = data[i];
      if (folder.id === fileId) {
        return folder;
      } else if (folder?.files) {
        let returnValues = findFile(folder.files, fileId);
        if (returnValues) {
          return returnValues;
        }
      }
    }
    return null;
  };

  const [currentFileName, setCurrentFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAction = (data, setCurrentFolder) => {
    if (data.id === downloadFile.id) {
      const url = studentFileDownloadPath(props?.student?.registeration, data.state.selectedFiles[0].name);
      window.open(url);
    }

    if (data.id === ChonkyActions.OpenFiles.id) {
      const file = findFile(fileData, data.payload.files[0].id);
      if (file?.isDir) {
        //   console.log("fileid", file.id);
        setCurrentFolder(file.id);
      }
    }
    if (data?.payload?.clickType == "double" && data?.payload?.file?.isDir == false) {
      // setCurrentFileName(data.payload.file.name);
      handleOpen();
      setFileUrl(studentFileDownloadPath(props?.student?.registeration, data?.payload?.file?.name));
    }
  };
  const handleActionWrapper = (data) => {
    handleAction(data, setCurrentFolder);
  };

  const [currentFolder, setCurrentFolder] = useState("0");
  const [files, setFiles] = useState(null);
  const [folderChain, setFolderChain] = useState(null);

  useEffect(() => {
    let folderChainTemp = [];
    let filesTemp = [];

    const [found, filesTemp1, folderChainTemp1] = folderSearch(fileData, folderChainTemp, currentFolder);
    if (found) {
      filesTemp = filesTemp1;
      folderChainTemp = folderChainTemp1;
    }

    setFolderChain(folderChainTemp);
    setFiles(filesTemp);
  }, [currentFolder]);

  const downloadFile = defineFileAction({
    id: "download_single_file",
    button: {
      name: "Download File",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName?.download,
    },
  });

  const fileActions = [downloadFile];

  return (
    <Box
      sx={{
        padding: "1rem 2rem 1rem 2rem",
        [`@media (max-width: 425px)`]: {
          padding: 0,
        },
      }}
    >
      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <iframe
          src={`${fileUrl}`}
          style={{
            background: "white",
          }}
          frameborder="0"
          height="80%"
          width="80%"
          title="pdf Viewer"
        ></iframe>
      </Modal>

      <div style={{ height: 300 }}>
        <FullFileBrowser fileActions={fileActions} files={files} folderChain={folderChain} onFileAction={handleActionWrapper} />
      </div>
    </Box>
  );
};

export default DocumentBrowser;
