import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import Registration from "./Forms/Registration";
import StudentInfo from "./Forms/StudentInfo";
// import IntendedCourse from "./Forms/IntendedCourse";
import AcademicQualification from "./Forms/AcademicQualification";
import EnglishProficiency from "./Forms/EnglishProficiency";
import ProfessionalExperience from "./Forms/ProfessionalExperience";
import Reference from "./Forms/Reference";
import Intake from "./Forms/Intake";
import Universities from "./Forms/Universities";
import DocumentUpload from "./Forms/DocumentUpload";
import FormSubmit from "./Forms/FormSubmit";
import PersonalStatement from "./Forms/PersonalStatement";
import { useForm } from "react-hook-form";
import EntranceTest from "./Forms/EntranceTest";
import RequirementsForm from "./Forms/RequirementsForm";

const steps = [
  // "Registration",
  // "Student Profile",
  // // "Level of Study",
  // "Education",
  // "Testing",
  // "Entrance Test",
  // "Reference",
  // "Employment",
  "Intake",
  "Universities Preference",
  "Additional",
  "Document Upload",
];

const ApplicationForm = ({
  setShowForm,
  onSubmit,
  setApplicationFormData,
  applicationFormData,
  setApplicationDataFinal,
  setSuccessOpen,
  edit,
  editData,
  onUpdate,
  student,
  setApplicationFormFiles,
  applicationFormFiles,
  sheetEditData,
  setSheetEditData,
  applications,
}) => {
  const nextStep = () => {
    setActiveSteps(activeSteps + 1);
  };
  const goBack = () => {
    setActiveSteps(activeSteps - 1);
  };

  const { control, handleSubmit } = useForm();

  const handleSteps = (step) => {
    switch (step) {
      // case 0:
      //   return (
      //     <Registration
      //       handleSubmit={handleSubmit}
      //       control={control}
      //       edit={edit}
      //       data={editData}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //       student={student}
      //     />
      //   );
      // case 1:
      //   return (
      //     <StudentInfo
      //       control={control}
      //       handleSubmit={handleSubmit}
      //       edit={edit}
      //       data={editData}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //       student={student}
      //       setApplicationFormFiles={setApplicationFormFiles}
      //     />
      //   );
      // case 2:
      //   return (
      //     <IntendedCourse
      //       control={control}
      //       handleSubmit={handleSubmit}
      //       edit={edit}
      //       data={editData}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       student={student}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //     />
      //   );
      // case 2:
      //   return (
      //     <AcademicQualification
      //       control={control}
      //       handleSubmit={handleSubmit}
      //       edit={edit}
      //       data={editData}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       goBack={goBack}
      //       student={student}
      //       setApplicationFormData={setApplicationFormData}
      //     />
      //   );
      // case 3:
      //   return (
      //     <EnglishProficiency
      //       eControl={control}
      //       handleSubmit={handleSubmit}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       edit={edit}
      //       data={editData}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //       student={student}
      //       setApplicationFormFiles={setApplicationFormFiles}
      //     />
      //   );
      // case 4:
      //   return (
      //     <EntranceTest
      //       eControl={control}
      //       handleSubmit={handleSubmit}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       edit={edit}
      //       data={editData}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //       student={student}
      //       setApplicationFormFiles={setApplicationFormFiles}
      //     />
      //   );
      // case 5:
      //   return (
      //     <Reference
      //       eControl={control}
      //       handleSubmit={handleSubmit}
      //       edit={edit}
      //       data={editData}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //       student={student}
      //     />
      //   );
      // case 6:
      //   return (
      //     <ProfessionalExperience
      //       edit={edit}
      //       eControl={control}
      //       handleSubmit={handleSubmit}
      //       data={editData}
      //       setShowForm={setShowForm}
      //       nextStep={nextStep}
      //       goBack={goBack}
      //       setApplicationFormData={setApplicationFormData}
      //       student={student}
      //     />
      //   );
      case 0:
        return (
          <Intake
            edit={edit}
            control={control}
            handleSubmit={handleSubmit}
            data={editData}
            setShowForm={setShowForm}
            nextStep={nextStep}
            goBack={goBack}
            setApplicationFormData={setApplicationFormData}
            student={student}
            sheetEditData={sheetEditData}
            setSheetEditData={setSheetEditData}
          />
        );
      case 1:
        return (
          <Universities
            edit={edit}
            control={control}
            handleSubmit={handleSubmit}
            data={editData}
            setShowForm={setShowForm}
            nextStep={nextStep}
            goBack={goBack}
            setApplicationFormData={setApplicationFormData}
            student={student}
            sheetEditData={sheetEditData}
            setSheetEditData={setSheetEditData}
            applicationFormData={applicationFormData}
          />
        );
      case 2:
        return (
          <RequirementsForm
            edit={edit}
            control={control}
            handleSubmit={handleSubmit}
            data={editData}
            setShowForm={setShowForm}
            nextStep={nextStep}
            goBack={goBack}
            setApplicationFormData={setApplicationFormData}
            student={student}
            sheetEditData={sheetEditData}
            setSheetEditData={setSheetEditData}
          />
        );
      case 3:
        return (
          <DocumentUpload
            applicationFormData={applicationFormData}
            setShowForm={setShowForm}
            nextStep={nextStep}
            goBack={goBack}
            setApplicationFormData={setApplicationFormData}
            setApplicationDataFinal={setApplicationDataFinal}
            setSuccessOpen={setSuccessOpen}
            onSubmit={onSubmit}
            onUpdate={onUpdate}
            edit={edit}
            data={editData}
            setApplicationFormFiles={setApplicationFormFiles}
            applicationFormFiles={applicationFormFiles}
            sheetEditData={sheetEditData}
            setSheetEditData={setSheetEditData}
            applications={applications}
            student={student}
          />
        );
      default:
        return setShowForm(false);
    }
  };
  const [activeSteps, setActiveSteps] = useState(0);

  return (
    <Box sx={{ bgcolor: "white", p: 3, width: "75rem", height: "35rem", borderRadius: "1rem", overflowY: "scroll", position: "relative" }}>
      <Typography fontSize="1.5rem" mb={2}>
        Working Sheet
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeSteps} alternativeLabel>
          {steps.map((label, idx) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {handleSteps(activeSteps)}
    </Box>
  );
};

export default ApplicationForm;
