import { Box, Button, Divider, Menu, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import DataCollection from "./DataCollection";
import Submission from "./Submission";
import Decision from "./Decision";
import Response from "./Response";
import Visa from "./Visa";
import Revert from "../Revert";
import EscalationData from "../EscalationData";
import RevertData from "../RevertData";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import EscalateButton from "../EscalateButton";
import Fade from "@mui/material/Fade";
import { KeyboardArrowDown } from "@mui/icons-material";
import { StateMachineAccessMethods } from "../../../../BussinessLogic/StateMachine/StateMachineAccess";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Status = ({ data, onUpdate, student, updateStudent, studentTimelineUpdate, fileUpload }) => {
  console.log("single application data", data);
  const [statusData, setStatusData] = useState([]);
  const [value, setValue] = useState(data && data?.stage ? data?.stage : 0);
  useEffect(() => {
    setValue(data && data?.stage ? data?.stage : 0);
    // TODO : set the value based on the stage instead of setting it to 0 by default on data change. if data is on stage 2 , this value is still 0 ?
    // also update the tabs stage here when data is changed. disabledTab , activeStates
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loggedInUser = getLoggedInUser();

  const [disabledTab, setDisabledTab] = useState({
    tab0: data?.stage > 0 ? false : true,
    tab1: data?.stage >= 1 ? false : true,
    tab2: data?.stage >= 2 ? false : true,
    tab3: data?.stage >= 3 ? false : true,
    tab4: data?.stage >= 4 ? false : true,
  });

  const [activeStates, setActiveStates] = useState({
    tab0: data?.stage > 0 ? false : true,
    tab1: data?.stage > 1 ? false : true,
    tab2: data?.stage > 2 ? false : true,
    tab3: data?.stage > 3 ? false : true,
    tab4: data?.stage > 4 ? false : true,
  });

  const createdAtObj = new Date(data?.createdAt);
  const updatedAtObj = new Date(data?.updatedAt);
  const createdAt = createdAtObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });
  const updatedAt = updatedAtObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      my={4}
      sx={{
        position: "relative",
      }}
    >
      {/* <Stack direction="row">
        {data?.escalation?.value && (
          <Box sx={{ flex: 1, mb: 2 }}>
            <EscalationData application={data} escalation={data.escalation} onUpdate={onUpdate} />
          </Box>
        )}

        {data?.revert?.value && (
          <Box sx={{ flex: 1, mb: 2 }}>
            <RevertData application={data} revert={data.revert} onUpdate={onUpdate} />
          </Box>
        )}
      </Stack> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            display: "none",
            "@media (max-width: 950px)": {
              display: "flex !important",
            },
          }}
        >
          {value === 0 ? "Application" : value === 1 ? "Submission" : value === 2 ? "Decision" : value === 3 ? "Response" : "Visa"}{" "}
          <span
            style={{
              paddingTop: ".5rem",
            }}
          >
            <KeyboardArrowDown />
          </span>
        </Button>
      </Box>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Box
          sx={{
            display: "none",
            "@media (max-width: 950px)": {
              display: "flex !important",
            },
          }}
        >
          <Tabs variant="solid" orientation="vertical" value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab
              sx={{
                fontSize: "1.1rem",
              }}
              disabled={data?.stage > 0 ? false : true}
              label="Application"
              {...a11yProps(0)}
            />{" "}
            <Tab
              sx={{
                fontSize: "1.1rem",
              }}
              disabled={data?.stage >= 1 ? false : true}
              label="Submission"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                fontSize: "1.1rem",
              }}
              disabled={data?.stage >= 2 ? false : true}
              label="Decision"
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                fontSize: "1.1rem",
              }}
              disabled={data?.stage >= 3 ? false : true}
              label="Response"
              {...a11yProps(3)}
            />
            <Tab
              sx={{
                fontSize: "1.1rem",
              }}
              disabled={data?.stage >= 4 ? false : true}
              label="Visa"
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
      </Menu>
      {/* <Box
        sx={{
          width: "100%",
          display: "none",
          "@media (max-width: 950px)": {
            display: "flex !important",
            justifyContent: "center",
            gap: 2,
          },
        }}
      >
        <EscalateButton updateApplicationPack={(updateFields) => onUpdate(updateFields, data?._id)} />
        <Revert updateApplicationPack={(updateFields) => onUpdate(updateFields, data?._id)} student={student} />
      </Box> */}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 2,
          p: 0,
          "@media (max-width: 950px)": {
            gap: 0,
          },
        }}
      >
        <Box
          sx={{
            "@media (max-width: 720px)": {
              display: "none !important",
            },
          }}
        >
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={value === 0 ? "primary" : "grey"} />
                <TimelineConnector />
              </TimelineSeparator>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={value === 1 ? "primary" : "grey"} />
                <TimelineConnector />
              </TimelineSeparator>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={value === 2 ? "primary" : "grey"} />
                <TimelineConnector />
              </TimelineSeparator>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={value === 3 ? "primary" : "grey"} />
                <TimelineConnector />
              </TimelineSeparator>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={value === 4 ? "primary" : "grey"} />
              </TimelineSeparator>
            </TimelineItem>
          </Timeline>
        </Box>
        <Box
          sx={{
            // background: "red",
            width: "15%",
            "@media (max-width: 1300px)": {
              width: "20% !important",
            },
            "@media (max-width: 950px)": {
              width: "5% !important",
            },
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            sx={{
              "@media (max-width: 950px)": {
                display: "none !important",
              },
            }}
          >
            <Tabs variant="solid" orientation="vertical" value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                sx={{
                  fontSize: "1.1rem",
                }}
                disabled={data?.stage > 0 ? false : true}
                label="Application"
                {...a11yProps(0)}
              />{" "}
              <Tab
                sx={{
                  fontSize: "1.1rem",
                  height: "6rem",
                }}
                disabled={data?.stage >= 1 ? false : true}
                label="Submission"
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  fontSize: "1.1rem",
                }}
                disabled={data?.stage >= 2 ? false : true}
                label="Decision"
                {...a11yProps(2)}
              />
              <Tab
                sx={{
                  fontSize: "1.1rem",
                  height: "5rem",
                }}
                disabled={data?.stage >= 3 ? false : true}
                label="Response"
                {...a11yProps(3)}
              />
              <Tab
                sx={{
                  fontSize: "1.1rem",
                  height: "4rem",
                }}
                disabled={data?.stage >= 4 ? false : true}
                label="Visa"
                {...a11yProps(4)}
              />
            </Tabs>
          </Stack>
          {/* <Box
            sx={{
              display: "flex",
              gap: 2,
              "@media (max-width: 950px)": {
                display: "none !important",
              },
            }}
          >
            <EscalateButton updateApplicationPack={(updateFields) => onUpdate(updateFields, data?._id)} />
            <Revert updateApplicationPack={(updateFields) => onUpdate(updateFields, data?._id)} student={student} />
          </Box> */}
        </Box>

        <Box
          sx={{
            width: "100%",
            borderLeft: "2px solid #e0e0e0",
            "@media (max-width: 950px)": {
              borderLeft: "none",
            },
          }}
        >
          <TabPanel value={value} index={0}>
            {data.stage > 0 ||
            StateMachineAccessMethods.roleAndUserCheck("Counselor", student?.counselor?.email) ||
            loggedInUser.name === student?.counselor?.name ? (
              <DataCollection
                setValue={setValue}
                data={data}
                onUpdate={onUpdate}
                studentTimelineUpdate={studentTimelineUpdate}
                setDisabledTab={setDisabledTab}
                disabledTab={disabledTab}
                student={student}
              />
            ) : (
              <ApplicationInProgressComp />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {data.stage > 1 ||
            StateMachineAccessMethods.selfAssignCheck(student) ||
            StateMachineAccessMethods.roleAndUserCheck("Application Officer" && "Counselor", student?.counselor?.email) ? (
              <Submission
                setValue={setValue}
                data={data}
                onUpdate={onUpdate}
                updateStudent={updateStudent}
                setDisabledTab={setDisabledTab}
                setActiveStates={setActiveStates}
                disabledTab={activeStates}
                student={student}
                fileUpload={fileUpload}
                studentTimelineUpdate={studentTimelineUpdate}
              />
            ) : (
              <ApplicationInProgressComp />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {data.stage > 2 ||
            StateMachineAccessMethods.selfAssignCheck(student) ||
            StateMachineAccessMethods.roleAndUserCheck("Application Officer", student?.counselor?.email) ? (
              <Decision
                setValue={setValue}
                data={data}
                onUpdate={onUpdate}
                setDisabledTab={setDisabledTab}
                setActiveStates={setActiveStates}
                disabledTab={activeStates}
                student={student}
                studentTimelineUpdate={studentTimelineUpdate}
              />
            ) : (
              <ApplicationInProgressComp />
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {data.stage > 3 ||
            data?.response?.completed ||
            StateMachineAccessMethods.roleAndUserCheck("Counselor", student.counselor.email) ||
            student.counselor?.name === student?.appOfficer?.name ? (
              <Response
                setValue={setValue}
                data={data}
                onUpdate={onUpdate}
                setDisabledTab={setDisabledTab}
                setActiveStates={setActiveStates}
                disabledTab={activeStates}
                student={student}
                studentTimelineUpdate={studentTimelineUpdate}
              />
            ) : (
              <ApplicationInProgressComp />
            )}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {data.stage > 4 || data?.response?.studentResponse?.response === "Accept Offer" ? (
              <Visa
                setValue={setValue}
                data={data}
                onUpdate={onUpdate}
                updateStudent={updateStudent}
                setDisabledTab={setDisabledTab}
                setActiveStates={setActiveStates}
                disabledTab={activeStates}
                student={student}
                studentTimelineUpdate={studentTimelineUpdate}
              />
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <>
                  <Typography variant="h4">Application is closed because it is not accepted by student</Typography>
                </>
              </Box>
            )}
          </TabPanel>
          <Stack
            direction="row"
            sx={{
              ml: 2,
              mb: 4,
              pt: 5,
              "@media (max-width: 950px)": {
                ml: "0 !important",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {data?.escalation?.value && (
              <Box sx={{ flex: 1, mb: 2 }}>
                <EscalationData application={data} escalation={data.escalation} onUpdate={onUpdate} />
              </Box>
            )}

            {data?.revert?.value && (
              <Box sx={{ flex: 1, mb: 2 }}>
                <RevertData application={data} revert={data.revert} onUpdate={onUpdate} />
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Status;

const ApplicationInProgressComp = ({ msg }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      {msg ? (
        <Typography>{msg}</Typography>
      ) : (
        <>
          <Typography variant="h3">Application in Progress</Typography>
          {/* <Typography variant="h3">Pending :</Typography> */}
        </>
      )}
    </Box>
  );
};
