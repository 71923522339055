import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack, StepButton, StepLabel, TextField, Link } from "@mui/material";
import { getLoggedInUser } from "../../../../utils/loggedInUser";
import { calculateActiveStep } from "./StatusCaclulator";
import { studentFileDownloadPath } from "../../../../utils/studentFileDownloadUtil";
import { Link as RouterLink } from "react-router-dom";

const Visa = ({ setValue, setDisabledTab, disabledTab, onUpdate, data, student, studentTimelineUpdate, updateStudent }) => {
  console.log("student", student);
  console.log("data", data);
  const steps = ["Awaiting Payment Slip", "Payment Received"];
  const [activeStep, setActiveStep] = useState(calculateActiveStep(data, "universityInformed", ["completed"]));
  const [proofFile, setProofFile] = useState("");
  const [comment, setComment] = useState("");

  // const nextStep = () => {
  //   setActiveStep(activeStep + 1);
  // };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProofFile(e.target.files[0].name);
    }
  };

  const completed = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        universityInformed: {
          uniInformedSubmission: {
            proof: proofFile,
            comments: comment,
            submittedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
    );
    // setActiveStep(1);
  };
  const nextTab = () => {
    onUpdate(
      {
        stage: 5,
      },
      data._id,
    );
  };
  const approved = () => {
    const user = getLoggedInUser();
    onUpdate(
      {
        universityInformed: {
          uniInformedSubmission: {
            proof: proofFile,
            comments: comment,
            submittedBy: { id: user._id, name: user.name, email: user.email },
          },
          approved: {
            approvedBy: { id: user._id, name: user.name, email: user.email },
          },
        },
      },
      data._id,
    );
    // setActiveStep(2);
    // nextTab();
    // onUpdate(
    //   {
    //     status: "Closed",
    //   },
    //   data._id,
    // );
  };

  const moveToVisa = () => {
    updateStudent(
      {
        moveToVisa: true,
      },
      student._id,
    );
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack spacing={2} alignItems="flex-start">
            {/* <Typography variant="subtitle2">Assigned To: Admin</Typography> */}
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2">Comments</Typography>
              <TextField
                id="outlined-multiline-static"
                placeholder="Enter Comments"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                defaultValue=""
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2">Upload Payment Slip</Typography>
              <TextField type="file" onChange={onFileChange} />
            </Box>
            <Button variant="contained" onClick={completed}>
              Submit
            </Button>
          </Stack>
        );
      case 1:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              px: 10,
              pt: 2,
              "@media (max-width: 950px)": {
                p: "0px !important",
              },
            }}
          >
            {/* <Typography variant="subtitle1">Assigned to: {student?.appOfficer?.name}</Typography> */}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              variant="subtitle1"
            >
              {" "}
              Submitted By{" "}
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {data?.universityInformed?.uniInformedSubmission?.submittedBy?.name}
              </span>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              variant="subtitle1"
            >
              Comments
              <span
                style={{
                  fontWeight: "500 ",
                }}
              >
                {data?.universityInformed?.uniInformedSubmission?.comments}
              </span>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              variant="subtitle1"
            >
              {" "}
              Payment Slip
              <Link download href={studentFileDownloadPath(data?.stdId, data?.universityInformed?.uniInformedSubmission?.proof)}>
                {" "}
                {data.decision?.universityDecision?.file}
              </Link>
            </Typography>

            <RouterLink to={`/visa/${data?.visaId}`}>
              <Button variant="contained" onClick={moveToVisa}>
                Move To Visa
              </Button>
            </RouterLink>
          </Box>
        );
      default:
        return;
    }
  };
  const approvedDateObj = new Date(data.universityInformed?.approved?.date);
  const approvedDate = approvedDateObj.toLocaleString("en-US", { timeZone: "Asia/Karachi", dateStyle: "full", timeStyle: "medium" });
  const handleData = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="subtitle1">Submitted By: {data?.universityInformed?.uniInformedSubmission?.submittedBy?.name}</Typography>
            <Typography variant="subtitle1">Proof: {data.universityInformed?.uniInformedSubmission?.proof}</Typography>
            <Typography variant="subtitle1">Comments: {data.universityInformed?.uniInformedSubmission?.comments}</Typography>
          </Box>
        );
      case 1:
        return (
          <Box>
            {/* <Typography variant="subtitle1">Approved By: {data?.universityInformed?.approved?.approvedBy?.name}</Typography> */}
            {/* <Typography variant="subtitle1">Date: {approvedDate}</Typography> */}
          </Box>
        );
      case 2:
        return;
      default:
        return "";
    }
  };

  const [stepNumber, setStepNumber] = useState(0);
  useEffect(() => {
    setActiveStep(calculateActiveStep(data, "universityInformed", ["completed"]));
  }, [{ data }]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, idx) => (
          <Step key={label} disabled={data.stage > 4 ? false : true}>
            <StepLabel>{label}</StepLabel>
            {/* <StepButton onClick={() => setStepNumber(idx)}>{label}</StepButton> */}
          </Step>
        ))}
      </Stepper>
      {data.stage > 4 ? <Box mt={4}>{handleData(stepNumber)}</Box> : <Box mt={4}>{handleSteps(activeStep)}</Box>}
    </Box>
  );
};

export default Visa;
